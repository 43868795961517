import ic_time from "../assets/home/ic_time_def@3x.png";
import ic_instructor from "../assets/video/ic_instructor_gray@3x.png";
import bg_lblogo from "../assets/home/bg_lblogo@3x.png";
import { useTranslation } from "react-i18next";
import { secondsToHHMMSS } from "../utils/timeUtil";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Image } from "./Image";

export const Card = ({
  id,
  type,
  horizontalLayout,
  jumpAction,
  name,
  duration,
  thum,
  tag,
  views,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const defaultJumpAction = useCallback(
    (id) => {
      navigate(`/video/${id}`);
    },
    [navigate],
  );
  return horizontalLayout ? (
    <div
      className="flex"
      onClick={() => (jumpAction ? jumpAction(id) : defaultJumpAction(id))}
    >
      <div className="w-2/5 relative">
        {tag && (
          <div className="absolute top-0 z-10 right-2.5 bg-carrot-slides-indicator-sel px-1 text-white rounded-b-md">
            {tag}
            {/* {t("video_tag_newest")} */}
          </div>
        )}
        {/* <div className='absolute top-0 right-2.5 bg-carrot-red px-1 text-white rounded-b-md'>
        {t("video_tag_hot")}
      </div> */}
        {/* <div className='absolute top-0 right-2.5 bg-carrot-highres-bg px-1 text-white rounded-b-md'>{t("video_tag_hidef")}</div> */}

        <Image
          src={thum}
          defaultPic={bg_lblogo}
          imgClassName="w-full rounded aspect-[1.76] object-cover"
        />
      </div>
      <div className="w-3/5 ml-2 relative shrink-0">
        <div
          className={`text-[${
            type === "ad" ? "16" : "14"
          }px] text-white overflow-hidden text-nowrap text-ellipsis`}
        >
          {name}
        </div>
        <div className="text-[12px] flex items-center mt-1">
          <img src={ic_time} alt="" className="w-4" />
          <div className="text-carrot-deactive">
            {secondsToHHMMSS(duration)}
          </div>
        </div>
        <div className="text-[12px] flex items-center absolute bottom-0">
          <img src={ic_instructor} alt="" className="w-3" />
          <div className="text-carrot-deactive ml-1">{views}</div>
        </div>
      </div>
    </div>
  ) : (
    <div
      className="w-full relative block"
      onClick={() => (jumpAction ? jumpAction(id) : defaultJumpAction(id))}
    >
      {tag && (
        <div className="absolute top-0 right-2.5 z-10 bg-carrot-slides-indicator-sel px-1 text-white rounded-b-md">
          {/* {t("video_tag_newest")} */}
          {tag}
        </div>
      )}
      {/* <div className="absolute top-0 right-2.5 bg-carrot-red px-1 text-white rounded-b-md"> */}
      {/*   {t("video_tag_hot")} */}
      {/* </div> */}
      {/* <div className="absolute top-0 right-2.5 bg-carrot-highres-bg px-1 text-white rounded-b-md"> */}
      {/*   {t("video_tag_hidef")} */}
      {/* </div> */}

      <Image
        src={thum}
        defaultPic={bg_lblogo}
        imgClassName="w-full rounded aspect-[1.76] object-cover"
      />
      <div
        className={`text-[${
          type === "ad" ? "16" : "14"
        }px] text-white overflow-hidden text-nowrap text-ellipsis`}
      >
        {name}
      </div>
      {type === "ad" ? (
        <div className="border text-carrot-gray text-[12px] border-spacing-px px-1 rounded absolute bottom-0 right-1">
          {t("video_tag_ads")}
        </div>
      ) : (
        <div className="text-[10px] flex items-center">
          <img src={ic_time} alt="" className="w-4" />
          <div className="text-carrot-deactive">
            {secondsToHHMMSS(duration)}
          </div>
        </div>
      )}
    </div>
  );
};
