import { createAction } from "@reduxjs/toolkit";
export const setUser = createAction("user/setUser", (payload) => ({
  payload,
}));
export const deleteUser = createAction("user/deleteUser");
export const setVideoLists = createAction("user/setVideoLists", (payload) => ({
  payload,
}));
export const addVideoToFavorites = createAction(
  "user/addVideoToFavorites",
  (payload) => ({
    payload,
  }),
);
export const addVideoToWatchHistory = createAction(
  "user/addVideoToWatchHistory",
  (payload) => ({
    payload,
  }),
);
export const updatePurchasedVideosById = createAction(
  "user/updatePurchasedVideosById",
  (payload) => ({
    payload,
  }),
);
