import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  articleCategories: [],
  articleList: [],
};

export const articleSlice = createSlice({
  name: "article",
  initialState,
  reducers: {
    setArticleList: (state, action) => {
      return {
        ...state,
        articleList: action.payload.articleList,
      };
    },
    setArticleCategories: (state, action) => {
      return {
        ...state,
        articleCategories: action.payload.categories,
      };
    },
    appendToArticleList: (state, action) => {
      const newList = action.payload.articleList.filter((v) => {
        return !state.articleList.some((a) => a.id === v.id);
      });
      return {
        ...state,
        articleList: [...state.articleList, ...newList],
      };
    },
  },
});

export default articleSlice.reducer;
