import { useCallback, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  getAllFavorites,
  getChannelInfo,
  getVideoPurchased,
  getWatchHistory,
} from "../apis";
import ic_mute_white from "../assets/video/ic_mute_white@3x.png";
import { CardGrid } from "../components/CardGrid";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { useTranslation } from "react-i18next";
export const VideoList = () => {
  const { listname, id } = useParams();
  const navigate = useNavigate();
  const [pageNumber, setPageNumber] = useState(1);
  const [videoListInfo, setVideoListInfo] = useState({});
  const [loading, setLoading] = useState(false);
  const [hasNextPage, setHasNextPage] = useState(false);
  const { t } = useTranslation();
  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        let res;
        switch (listname) {
          case "channel":
            res = await getChannelInfo(id, pageNumber, 10);
            break;
          case "favorite":
            res = await getAllFavorites(pageNumber, 10);
            res = { name: t("my_favourite"), videos: res.records, ...res };
            delete res.records;
            break;
          case "history":
            res = await getWatchHistory(pageNumber, 10);
            res = { name: t("watch_history"), videos: res.records, ...res };
            delete res.records;
            break;
          case "purchased":
            res = await getVideoPurchased(pageNumber, 10);
            res = { name: t("purchased_videos"), videos: res.records, ...res };
            delete res.records;
            break;
          default:
            navigate("/index");
            return;
        }
        setVideoListInfo((channelInfo) => {
          const newVideos = res.videos.filter((v) => {
            return !channelInfo?.videos?.some((a) => v.id === a.id);
          });
          return {
            ...channelInfo,
            ...res,
            videos: [...(channelInfo?.videos || []), ...newVideos],
          };
        });
        setHasNextPage(res.videos.length >= 10);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    })();
  }, [id, pageNumber, listname, navigate, t]);

  const loadMore = useCallback(() => {
    if (hasNextPage) {
      setPageNumber(pageNumber + 1);
    }
  }, [pageNumber, hasNextPage]);

  const [sentryRef] = useInfiniteScroll({
    onLoadMore: loadMore,
    loading,
    hasNextPage,
    rootMargin: "0px 0px 300px 0px",
  });

  return (
    <>
      <div className="flex z-10 bg-carrotbg top-0 left-0 w-full h-11 justify-center items-center text-white text-[17px] border-b border-b-[rgba(0,0,0,0.3)] font-medium">
        <div
          className="absolute left-2 h-11 w-11 flex justify-center items-center"
          onClick={() => {
            navigate(-1);
          }}
        >
          <img src={ic_mute_white} alt="" className="h-[19px]" />
        </div>
        {videoListInfo.name}
      </div>
      <div className="px-3">
        <CardGrid items={videoListInfo?.videos} layoutType={2} />
        {(loading || hasNextPage) && (
          <div ref={sentryRef} className="text-white text-center h-7">
            {t("video_loading")}
          </div>
        )}
      </div>
    </>
  );
};
