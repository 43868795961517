import { Swiper, SwiperSlide } from "swiper/react";
import { Card } from "./Card";
import { Heading } from "./Heading";

export const CardGrid = ({
  items = [],
  layoutType = 3, // 1: slider 2: 2-col 3: 1-col
  heading,
  className,
  cardDirection = "vertical",
  moreAction = () => {},
  jumpAction,
  total,
}) => {
  const cols = layoutType === 3 ? 1 : 2;
  const gridcols = `grid-cols-${cols}`;

  const slide = (
    <div className="mt-4">
      <Swiper
        className={"relative rounded fixhidden" + className}
        slidesPerView={2.25}
        spaceBetween={5}
      >
        {items.map((item) => (
          <SwiperSlide className="relative" key={item.id}>
            <Card id={item.id} {...item} jumpAction={jumpAction} />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );

  const grid = (
    <div>
      <div className={"grid " + gridcols + " gap-x-[6px] gap-y-4 my-3"}>
        {items.map((item) => (
          <Card
            key={item.id}
            jumpAction={jumpAction}
            horizontalLayout={cardDirection === "horizontal"}
            {...item}
          />
        ))}
      </div>
    </div>
  );

  return (
    <>
      {heading && (
        <Heading
          className="my-4"
          title={heading}
          more={moreAction}
          count={total}
        />
      )}
      {layoutType === 1 ? slide : grid}
    </>
  );
};
