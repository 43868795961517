import { get, post } from "../utils/http.js";
export const login = async ({ phone, password, country }) => {
  return await post("/api/home/login", { phone, password, country });
};

export const register = async ({
  phone,
  password,
  country,
  code,
  promocode,
}) => {
  return await post("/api/home/register", {
    phone,
    password,
    country,
    code,
    promocode,
  });
};

export const getVerificationCode = async ({ country, phone }) => {
  return await post("/api/home/getVerificationCode", { country, phone });
};

export const getCurrentUserProfile = async () => {
  return await get("/api/user/cur_user");
};

export const getCollectionAndWatchHistory = async () => {
  return await get("/api/user/collect_n_watch_list");
};

export const getHomePageData = async () => {
  return await get("/api/home/");
};

export const getChannelInfo = async (chanelId, pageNum, pageSize) => {
  return await get(
    `/api/channel?channel_id=${chanelId}&pageNum=${pageNum}&pageSize=${pageSize}`,
  );
};
export const nextGroupByChannelId = async (chanelId) => {
  return await get(`/api/channel/next?channel_id=${chanelId}`);
};

export const getVideoDetail = async (id) => {
  return await get(`/api/video/${id}`);
};

export const getVideoPlayUrl = async (id) => {
  return await get(`/api/video/play/${id}`);
};

export const setAsfavorite = async (id) => {
  return await post(`/api/user/collect/${id}`);
};

export const getAllFavorites = async (pageNum, pageSize) => {
  return await get(`/api/user/collect?pageNum=${pageNum}&pageSize=${pageSize}`);
};

export const guessWhatILike = async (id, pageNum, pageSize) => {
  return await get(
    `/api/home/guess?video_id=${id}&pageNum=${pageNum}&pageSize=${pageSize}`,
  );
};
export const getWatchHistory = async (pageNum, pageSize) => {
  return await get(
    `/api/user/watch_history?pageNum=${pageNum}&pageSize=${pageSize}`,
  );
};

export const getVideoPurchased = async (pageNum, pageSize) => {
  return await get(
    `/api/user/videos_purchased?pageNum=${pageNum}&pageSize=${pageSize}`,
  );
};

export const getAllAdvs = async () => {
  return await get("/api/home/ads");
};

export const getAllPopups = async () => {
  return await get("/api/home/notices");
};

export const searchVideo = async (keyword, pageNum, pageSize) => {
  return await post(
    `/api/video/search?pageNum=${pageNum}&pageSize=${pageSize}`,
    { keyword },
  );
};

export const getHotKeyword = async () => {
  return await get("/api/home/hot_keywords");
};

export const bindInviteCode = async (invitation_code_from) => {
  return await post(`/api/user/bind/invite_code`, { invitation_code_from });
};

export const getSharePageInfo = async () => {
  return await get(`/api/user/sharePageInfo`);
};

export const getAllNovelCategories = async () => {
  return await get("/api/novel/categories");
};

export const getNovelList = async (categoryId, pageNum, pageSize) => {
  return await get(
    `/api/novel/?categoryId=${categoryId}&pageNum=${pageNum}&pageSize=${pageSize}`,
  );
};
export const getNovelDetail = async (novelId) => {
  return await get(`/api/novel/${novelId}`);
};

export const getVIPPriceList = async () => {
  return await get("/api/home/vip_price_list");
};

export const getRechargePriceList = async () => {
  return await get("/api/home/recharge_price_list");
};

export const buyVip = async (vip_price_id) => {
  return await post(`/api/user/buy/vip/` + vip_price_id, {});
};

export const recharge = async (recharge_price_id) => {
  return await post(`/api/user/recharge/` + recharge_price_id, {});
};

export const buyVideo = async (video_id) => {
  return await post(`/api/user/buy/video/` + video_id, {});
};

export const feedback = async (data) => {
  return await post(`/api/user/feedback`, data);
};

export const getCSLink = async () => {
  return await get(`/api/home/cs_link`);
};

export const getBalanceRecord = async (params) => {
  return await post('/api/user/balance_record?pageNum=' + params.current + '&pageSize=' + params.size, {});
};

export const createOrderForRecharge = async (recharge_price_id) => {
  return await get(`/api/pay/createOrderForRecharge/`+recharge_price_id);
};

