export const secondsToHHMMSS = (seconds) => {
  // Ensure seconds is a valid number
  if (isNaN(seconds) || seconds < 0) {
    return "Invalid input";
  }

  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const secondsRemainder = seconds % 60;

  // Pad single-digit hours, minutes, and seconds with leading zeros
  const paddedHours = hours.toString().padStart(2, "0");
  const paddedMinutes = minutes.toString().padStart(2, "0");
  const paddedSecondsRemainder = secondsRemainder.toString().padStart(2, "0");

  return `${paddedHours}:${paddedMinutes}:${paddedSecondsRemainder}`;
};
