import { useEffect, useRef, useState } from "react";
import DialogBase from "./dialogs/DialogBase";
const SelectPopUp = ({ value, onChange, options, optionEle }) => {
  const [isOpen, setOpen] = useState(false);
  const [currentItem, setCurrentItem] = useState();
  const listRef = useRef(null);
  useEffect(() => {
    let item = options.find((v) => v.value === value);
    setCurrentItem(
      item || (options.length > 0 ? options[0] : { label: "Please Choose" }),
    );
  }, [value, options]);
  useEffect(() => {
    if (isOpen) {
      const itemElement = listRef.current?.querySelector(
        `[data-id="${value}"]`,
      );
      if (itemElement) {
        itemElement.scrollIntoView();
      }
    }
  }, [isOpen, value]);
  const openPopUp = () => {
    setOpen(true);
  };

  const valueChange = (value) => {
    onChange(value);
    setOpen(false);
  };
  return (
    <div className="select-wraper">
      <div className="select-current" onClick={openPopUp}>
        {currentItem && currentItem.label}
      </div>
      <DialogBase
        isOpen={isOpen}
        onMaskClick={() => {
          setOpen(false);
        }}
      >
        <div
          ref={listRef}
          className="absolute bottom-0 left-0 w-full h-1/4 px-2 flex flex-col bg-carrot-inputbg overflow-y-auto rounded-t-md"
        >
          {options.map((v) => (
            <div
              key={v.value}
              className={`w-full h-12 min-h-12 flex justify-center items-center text-base ${currentItem?.value === v.value ? "text-carrot-slides-indicator-sel" : "text-white"} border-b-[0.5px] border-gray-500/80 last:border-b-0`}
              onClick={() => {
                valueChange(v.value);
              }}
              data-id={v.value}
            >
              {optionEle(v)}
            </div>
          ))}
        </div>
      </DialogBase>
    </div>
  );
};

export default SelectPopUp;
