import { createSlice } from "@reduxjs/toolkit";
import { produce } from "immer";

const customizedCategoriesJSON = localStorage.getItem("customizedCategories");
const initialState = {
  categories: {},
  customizedCategories: customizedCategoriesJSON
    ? JSON.parse(customizedCategoriesJSON)
    : {},
};

export const homeSlice = createSlice({
  name: "home",
  initialState,
  reducers: {
    setHomeData: (state, action) => {
      state.categories = action.payload.categories;
      const cates = Object.values(state.categories).map(({ id, name }) => ({
        id,
        name,
      }));

      const restCates = cates.filter((v) => {
        if (!state.customizedCategories.myCates) {
          return true;
        } else {
          return !state.customizedCategories.myCates?.some(
            (t) => v.id === t.id,
          );
        }
      });
      state.customizedCategories = {
        restCates: state.customizedCategories.myCates ? restCates : [],
        myCates: state.customizedCategories.myCates || cates,
      };
      localStorage.setItem(
        "customizedCategories",
        JSON.stringify(state.customizedCategories),
      );
    },
    setCustomizedCategories: (state, action) => {
      state.customizedCategories = action.payload;
      localStorage.setItem(
        "customizedCategories",
        JSON.stringify(state.customizedCategories),
      );
    },
    updateChannelVideos: (state, action) => {
      const { cateid, id, videos } = action.payload;
      return produce(state, (draft) => {
        draft.categories[cateid].channels[id].videos = videos.reduce(
          (obj, item) => {
            obj[item.id] = item;
            return obj;
          },
          {},
        );
      });
    },
    updateVideoById: (state, action) => {
      const { cateId, channelId, videoId, videoInfo } = action.payload;
      return produce(state, (draft) => {
        draft.categories[cateId].channels[channelId].videos[videoId] = {
          ...draft.categories[cateId].channels[channelId].videos[videoId],
          ...videoInfo,
        };
      });
    },
  },
});

export default homeSlice.reducer;
