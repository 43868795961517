import { useState } from "react";
import DialogBase from "./DialogBase";
import { useTranslation } from "react-i18next";
import bg_popup_head from "../../assets/popup/bg_popup_head.png";
import {feedback} from "../../apis";

const InputFeedbackDialog = ({ onClose }) => {
  const [isOpen, setOpen] = useState(true);
  const [inputValue, setInputValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();

  const closeDialog = () => {
    setOpen(false);
    onClose();
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  async function handleButtonClick() {
    setIsLoading(true);
    feedback({content: inputValue}).then(() => {
      alert(t("operation_successful"));
      onClose();
    }).catch( err => {
      alert(err.message);
    }).finally(() => {
      setIsLoading(false);
    })
  }

  const spinKeyframes = `
    @keyframes spin {
      0% { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
    }
  `;

  return (
      <div>
        <style>{spinKeyframes}</style>
        <DialogBase
            isOpen={isOpen}
            onMaskClick={() => {
              closeDialog();
            }}>

          <div className="w-5/6 bg-white relative py-4 px-8 rounded-lg shadow-lg">
            <div className="absolute -top-12 left-1/2 transform -translate-x-1/2">
              <div className="rounded-full w-24">
                <img src={bg_popup_head} alt="Icon" />
              </div>
            </div>
            <div className="flex flex-col h-full">
              <div className="flex-1 overflow-y-auto mt-10">
                <div>{t("feedback_title")}</div>
              </div>

              <textarea className='bg-gray-light rounded-mg p-5 mt-3' rows='5'
                        onChange={handleInputChange} value={inputValue}/>

              <div
                  className="bottom-0 left-0 w-full text-white h-20 py-4 space-x-4 pl-5 pr-5 flex justify-center items-center">
                <button
                    style={{ fontSize: "17px", flex: "2" }}
                    className="px-4 py-3 rounded bg-carrot-gray"
                    onClick={onClose}>
                  {t("cancel")}
                </button>
                <button
                    style={{ backgroundColor: "#F8A034", fontSize: "17px", flex: "2" }}
                    className="px-4 py-3 rounded" disabled={isLoading} onClick={handleButtonClick}>

                  {isLoading ? (
                      <svg
                          className="animate-spin h-5 w-5 text-white mx-auto"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                      >
                        <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                        ></circle>
                        <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.96 7.96 0 014 12H0c0 2.294.962 4.373 2.621 5.937l1.379-1.646z"
                        ></path>
                      </svg>
                  ) : (
                      t("confirm")
                  )}
                </button>
              </div>

            </div>
          </div>

        </DialogBase>
      </div>
  );
};

export default InputFeedbackDialog;
