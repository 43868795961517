import { useState } from "react";
import DialogBase from "./DialogBase";
import bg_popup_head from "../../assets/popup/bg_popup_head.png";
import { useNavigate } from "react-router-dom";

const NoticeDialog = ({ noticeObj, onClose }) => {
  const [isOpen, setOpen] = useState(true);
  const navigate = useNavigate();
  const CancelIcon = () => {
    return (
      <div
        onClick={() => setOpen(false)}
        className="flex justify-center items-center"
      >
        <div className="flex justify-center items-center w-12 h-12 bg-white rounded-full">
          <svg
            className="w-6 h-6 text-carrotbg"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18L18 6M6 6l12 12"
            ></path>
          </svg>
        </div>
      </div>
    );
  };

  function jumpTo(jump_url) {
    if (jump_url.startsWith("http")) {
      window.open(jump_url); // 通过浏览器打开URL
    } else {
      navigate(jump_url);
    }
    setOpen(false);
  }

  return (
    <div>
      {/*首页弹窗通知*/}
      {noticeObj?.position === 0 ? (
        <DialogBase
          isOpen={isOpen}
          onMaskClick={() => {
            setOpen(false);
          }}
          onClose={onClose}
        >
          <div className="w-5/6 h-4/6 bg-white relative py-4 px-8 rounded-lg shadow-lg">
            <div className="absolute -top-12 left-1/2 transform -translate-x-1/2">
              <div className="rounded-full w-24">
                <img src={bg_popup_head} alt="Icon" />
              </div>
            </div>
            <div className="flex flex-col h-full">
              <div className="flex-1 overflow-y-auto mt-10">
                <div
                  className="html-content"
                  dangerouslySetInnerHTML={{ __html: noticeObj.content }}
                />
              </div>
              <div className="pb-2 mt-2">
                <button
                  onClick={() => setOpen(false)}
                  className="w-full bg-carrot-slides-indicator-sel text-white p-2 rounded"
                >
                  {noticeObj.button_text}
                </button>
              </div>
            </div>
          </div>
        </DialogBase>
      ) : (
        ""
      )}

      {noticeObj?.position === 1 ? (
        <div className="overflow-hidden whitespace-nowrap">
          <div className="inline-block animate-marquee">
            <span className="mx-4 text-carrot-slides-indicator-sel">
              {noticeObj.content}
            </span>
          </div>
        </div>
      ) : (
        ""
      )}

      {noticeObj?.position === 2 ? (
        <DialogBase
          isOpen={isOpen}
          onMaskClick={() => {
            setOpen(false);
          }}
          onClose={onClose}
        >
          <div className="w-5/6 relative rounded-lg shadow-lg">
            <div className="flex flex-col h-full">
              <div className="flex-1 flex justify-center items-center overflow-y-auto">
                <img src={noticeObj.pic_url} className="image w-full" alt="" />
              </div>
              <div className="pb-2 my-5">
                <button
                  onClick={() => jumpTo(noticeObj.jump_url)}
                  className="w-full bg-carrot-slides-indicator-sel text-white p-2 rounded"
                >
                  {noticeObj.button_text}
                </button>
              </div>
              <CancelIcon />
            </div>
          </div>
        </DialogBase>
      ) : (
        ""
      )}
    </div>
  );
};

export default NoticeDialog;
