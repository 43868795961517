import { useCallback, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getAllAdvs, getAllPopups, getHomePageData } from "../apis";
import useTimer from "../hooks/useTimer";
import { Image } from "../components/Image";
import startup from "../assets/home/startup.png";
import { disableScroll, enableScroll } from "../utils";

import {
  getAdvByPosition,
  setAdvs,
  setNotifications,
  setStartUpShowed,
} from "../store/actions/advsAction";
import { setHomeData } from "../store/actions/homeActions";
export const StartUp = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const btRef = useRef(null);
  const { seconds } = useTimer({
    expireTime: process.env.NODE_ENV === 'development' ? 1 : 5,
    autostart: true,
    countdown: true,
    onExpire: () => {
      enableScroll();
      loadAdvs();
      navigate(searchParams.get("redirect") || "/index", {
        replace: true,
        state: { isFromStartUp: true },
      });
    },
  });
  const advs = useSelector((state) => getAdvByPosition(state, 0));
  const loadAdvs = useCallback(async () => {
    try {
      const res = await getAllAdvs();
      dispatch(setAdvs(res));
    } catch (error) {}
  }, [dispatch]);

  const loadData = useCallback(async () => {
    try {
      const cates = await getHomePageData();
      dispatch(setHomeData({ categories: cates }));
      const notices = await getAllPopups();
      dispatch(setNotifications(notices));
    } catch (e) {}
  }, [dispatch]);

  useEffect(() => {
    if (advs.length === 0) {
      loadAdvs();
    }
    document.title = "directing..."
  }, [advs, loadAdvs]);

  useEffect(() => {
    dispatch(setStartUpShowed());
    loadData();
  }, [loadData, dispatch]);

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, document.body.scrollHeight);
      disableScroll();
    }, 0);
  }, []);

  return (
    <div className="w-lvw h-[calc(100lvh+50px)]">
      <Image
        src={advs[0]?.pic_url}
        imgClassName="w-full h-full object-fill"
        defaultPic={startup}
      />
      <div ref={btRef}></div>
      <div className="fixed flex justify-center items-center top-5 bg-carrot-gray/80 rounded-full right-8 text-white text-xl w-10 h-10">
        {seconds}
      </div>
    </div>
  );
};
