import { createSlice } from "@reduxjs/toolkit";

const advsJSON = localStorage.getItem("advs");

const initialState = {
  advList: advsJSON ? JSON.parse(advsJSON) : [],
  isStartUpShowed: false,
  notifications: [],
  isNoticeShowed: false,
};

export const advsSlice = createSlice({
  name: "advs",
  initialState,
  reducers: {
    setAdvs: (state, action) => {
      state.advList = action.payload;
      localStorage.setItem("advs", JSON.stringify(state.advList));
    },
    setStartUpShowed: (state) => {
      state.isStartUpShowed = true;
    },
    setNotifications: (state, action) => {
      state.notifications = action.payload;
    },
    setNoticeShowed: (state) => {
      state.isNoticeShowed = true;
    },
  },
});

export default advsSlice.reducer;
