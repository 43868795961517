import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getNovelDetail } from "../apis";
export const Article = () => {
  const { id } = useParams();
  const [novel, setNovel] = useState({});
  useEffect(() => {
    (async () => {
      try {
        const res = await getNovelDetail(id);
        setNovel(res);
      } catch (error) {}
    })();
  }, [id]);
  return (
    <div className="px-3 text-white">
      <div className="border-b py-2">
        <h1 className=" text-3xl">{novel.title}</h1>
      </div>
      <div
        dangerouslySetInnerHTML={{ __html: novel.content }}
        className=" mt-8 px-2"
      ></div>
    </div>
  );
};
