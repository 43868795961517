import { Swiper, SwiperSlide } from "swiper/react";
import { Categories } from "../components/Categories";
import { getPic } from "../config/randomPic";
export const PicturesPage = () => {
  const items = [{}, {}, {}];
  return (
    <div className="px-4 pt-4">
      <Categories
        categoryItems={[
          { id: 1, name: "无码大图" },
          { id: 2, name: "绝美私拍" },
          { id: 3, name: "明星靓图" },
          { id: 4, name: "街拍美女" },
          { id: 5, name: "AI 美图" },
        ]}
        itemClassName={
          "bg-carrot-inputbg rounded-full px-3 py-2 text-white text-sm"
        }
      />
      <div>
        {new Array(20).fill(0).map(() => {
          return (
            <div>
              <h3 className="text-white py-3">This is a fucking title</h3>
              <Swiper
                className={"relative fixhidden items-stretch"}
                slidesPerView={2.25}
                spaceBetween={5}
              >
                {items.map((item) => (
                  <SwiperSlide className="relative !h-auto" key={item.id}>
                    <img
                      src={getPic()}
                      alt=""
                      className="w-[158px] h-[120px] object-cover rounded"
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          );
        })}
      </div>
    </div>
  );
};
