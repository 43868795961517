import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {bindInviteCode} from "../../apis";

const InputInviteCodeDialog = ({ isVisible, onClose, onSuccess }) => {
  const [inputValue, setInputValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  if (!isVisible) return null;

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  async function handleButtonClick() {
    setIsLoading(true);

    bindInviteCode(inputValue).then(() => {
      alert(t("operation_successful"));
      onClose();
      onSuccess();
    }).catch( err => {
      alert(err.message);
    }).finally(() => {
      setIsLoading(false);
    })
  }

  return (
    <div
      className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center"
      onClick={handleOverlayClick}
    >
      <div className="bg-white p-6 rounded shadow-lg relative w-full mx-[40px] text-center">
        <div className="text-xl font-medium">{t("input_inviter")}</div>
        <input
          style={{ backgroundColor: "#EFEFF3", fontSize: "17px" }}
          className="w-full h-12 mt-[20px] mb-[10px]
                    text-center"
          onChange={handleInputChange}
          value={inputValue}
        />
        <button
          style={{ backgroundColor: "#F8A034", fontSize: "17px" }}
          className="w-full h-12 rounded text-white"
          onClick={handleButtonClick}
          disabled={isLoading}
        >
          {isLoading ? (
            <svg
              className="animate-spin h-5 w-5 text-white mx-auto"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.96 7.96 0 014 12H0c0 2.294.962 4.373 2.621 5.937l1.379-1.646z"
              ></path>
            </svg>
          ) : (
            t("confirm")
          )}
        </button>
      </div>
    </div>
  );
};

export default InputInviteCodeDialog;
