import { useEffect, useRef } from "react";
import { createPortal } from "react-dom";

const DialogBase = ({ children, isOpen, onClose, onOpen, onMaskClick }) => {
  const dialogRef = useRef(null);
  useEffect(() => {
    if (!isOpen) {
      document.body.style = "";
      onClose && onClose();
    } else {
      document.body.style = "touch-action: none; overflow: hidden;";
      onOpen && onOpen();
    }
  }, [isOpen, onClose, onOpen]);
  return (
    isOpen &&
    createPortal(
      <div
        className="fixed top-0 left-0 bg-black/60 w-full h-full z-[99] flex justify-center items-center"
        onClick={(event) => {
          if (dialogRef.current === event.target) {
            onMaskClick();
          }
        }}
        ref={dialogRef}
      >
        {children}
      </div>,
      document.body,
    )
  );
};

export default DialogBase;
