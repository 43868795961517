import { Categories } from "../components/Categories";
import { useCallback, useEffect, useState } from "react";
import { getAllNovelCategories, getNovelList } from "../apis";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useNavigate, Link } from "react-router-dom";
import PullToRefresh from "react-simple-pull-to-refresh";
import useInfiniteScroll from "react-infinite-scroll-hook";
import {
  setArticleCategories,
  appendToArticleList,
  setArticleList,
} from "../store/actions/articleAction";

export const ArticlesPage = () => {
  const [pageNum, setPageNum] = useState(1);
  const [loading, setLoading] = useState(false);
  const [hasNextPage, setHasNextPage] = useState(false);
  const navigate = useNavigate();
  const { categoryid } = useParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { articleCategories, articleList } = useSelector(
    (state) => state.article,
  );

  const loadCategories = useCallback(async () => {
    dispatch(
      setArticleCategories({ categories: await getAllNovelCategories() }),
    );
  }, [dispatch]);

  const loadData = useCallback(
    async (cateId = categoryid, pageNum) => {
      try {
        setLoading(true);
        const result = await getNovelList(cateId || -1, pageNum, 12);
        dispatch(appendToArticleList({ articleList: result.records }));
        setHasNextPage(result.records.length >= 12);
      } catch (error) {
      } finally {
        setLoading(false);
      }
    },
    [dispatch, categoryid],
  );
  useEffect(() => {
    loadData(categoryid, pageNum);
  }, [categoryid, pageNum, loadData]);

  const loadMore = useCallback(async () => {
    if (hasNextPage) {
      setPageNum(pageNum + 1);
    }
  }, [pageNum, hasNextPage]);

  const [sentryRef] = useInfiniteScroll({
    onLoadMore: loadMore,
    loading,
    hasNextPage,
    rootMargin: "0px 0px 300px 0px",
  });

  useEffect(() => {
    if (articleCategories.length <= 0) {
      loadCategories();
    }
    dispatch(setArticleList({ articleList: [] }));
    loadData(categoryid, 1);
    setPageNum(1);
  }, [
    categoryid,
    articleCategories.length,
    dispatch,
    loadCategories,
    loadData,
  ]);

  return (
    <div className="px-3 pt-3">
      <Categories
        categoryItems={articleCategories}
        itemClassName={
          "bg-carrot-inputbg rounded-full px-3 py-2 text-white text-sm"
        }
        activeItemClassName={
          "bg-carrot-inputbg rounded-full px-3 py-2 text-sm text-carrot-slides-indicator-sel"
        }
        currentItemId={categoryid}
        jumpAction={(id) => {
          navigate(`/articles/${id}`);
        }}
      />
      <PullToRefresh
        onRefresh={async () => {
          await loadData(categoryid, 1);
          setPageNum(1);
        }}
      >
        <div>
          {articleList.map((v, i) => {
            return (
              <Link
                to={`/article/${v.id}`}
                key={v.id}
                className="flex items-center justify-between py-[20px] border-solid border-carrot-gray-40 border-b first:border-t"
              >
                <h3 className="text-white text-base">{v.title}</h3>
                <span className="text-carrot-deactive text-sm">
                  {v.count}&nbsp;
                  {t("article_read_count")}
                </span>
              </Link>
            );
          })}
        </div>
        {(loading || hasNextPage) && (
          <div ref={sentryRef} className="text-white text-center h-7">
            {t("video_loading")}
          </div>
        )}
      </PullToRefresh>
    </div>
  );
};
