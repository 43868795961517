import { createAction } from "@reduxjs/toolkit";
export const pushToHistory = createAction(
  "searchHistory/pushToHistory",
  (payload) => ({
    payload,
  }),
);
export const clearHistory = createAction(
  "searchHistory/clearHistory",
  (payload) => ({
    payload,
  }),
);
export const setSearchResult = createAction(
  "searchHistory/setSearchResult",
  (payload) => ({
    payload,
  }),
);
export const updateVideoById = createAction(
  "searchHistory/updateVideoById",
  (payload) => ({
    payload,
  }),
);
