import React from "react";
import { createBrowserRouter } from "react-router-dom";
import App from "../App";
import TabBarLayout from "../components/TabLayouts";
import { NotFoundPage } from "../pages/404";
import { ArticlesPage } from "../pages/Articles";
import { HomePage } from "../pages/Home";
import { VideoList } from "../pages/VideoList";
import { LoginPage } from "../pages/Login";
import { PicturesPage } from "../pages/Pictures";
import { ProfilePage } from "../pages/Profile";
import { RechargePage } from "../pages/Recharge";
import { RechargeVipPage } from "../pages/RechargeVip";
import { SearchPage } from "../pages/Search";
import { ShareToEarnVIPPage } from "../pages/ShareToEarnVIP";
import { VideoPage } from "../pages/Video";
import ProtectedRoutes from "./protectedRoute";
import { StartUp } from "../pages/Startup";
import { CategoriesPage } from "../pages/Categories";
import { Article } from "../pages/Article";
import { PaymentSuccessPage } from "../pages/PaymentSuccess";
import { BalanceDetailsPage } from "../pages/BalanceDetails";
import { PuaPage} from "../pages/pua/pua";

const router = createBrowserRouter([
  {
    path: "startup",
    element: <StartUp />,
  },
  {
    path: "/",
    element: <App />,
    children: [
      {
        element: <TabBarLayout />,
        children: [
          {
            path: "/index/:cateid?",
            element: <HomePage />,
          },
          {
            element: <ProtectedRoutes />,
            children: [
              {
                path: "profile",
                element: <ProfilePage />,
              },
              {
                path: "recharge",
                element: <RechargePage />,
              },
              {
                path: "shareToEarnVIP",
                element: <ShareToEarnVIPPage />,
              },
              {
                path: "rechargeVip",
                element: <RechargeVipPage />,
              },
            ],
          },
          {
            path: "articles/:categoryid?",
            element: <ArticlesPage />,
          },
          {
            path: "article/:id",
            element: <Article />,
          },
          {
            path: "videolist/:listname/:id?",
            element: <VideoList />,
          },
          {
            path: "pictures",
            element: <PicturesPage />,
          },
        ],
      },
      {
        path: "login",
        element: <LoginPage />,
      },
      {
        path: "register",
        element: <LoginPage />,
      },
      {
        path: "paySuccess",
        element: <PaymentSuccessPage />,
      },
      {
        path: "balanceDetails",
        element: <BalanceDetailsPage />,
      },
      {
        path: "video/:id",
        element: <VideoPage />,
      },
      {
        path: "search",
        element: <SearchPage />,
      },
      {
        path: "categories",
        element: <CategoriesPage />,
      },
      {
        path: "pua",
        element: <PuaPage />,
      },
      {
        path: "*",
        element: <NotFoundPage />,
      },
    ],
  },
]);

export default router;
