import share_bg from "../assets/profile/shareToVIP/ic_share_bg@3x.png";
import ic_welfare from "../assets/profile/shareToVIP/ic_share_welfare@3x.png";
import InputInviteCodeDialog from "../components/dialogs/InputInviteCodeDialog";
import { useEffect, useState } from "react";
import { getSharePageInfo } from "../apis";
import { useTranslation } from "react-i18next";

function CustomText({ count }) {
    const { t } = useTranslation();

    // Get the translation string
    const translationString = t('share_free_watch', { count });

    // Replace {{count}} with <span> element containing the count
    const htmlString = translationString.replace(
        /{{count}}/,
        `${count}`
    );

    return (
        <div dangerouslySetInnerHTML={{ __html: htmlString }} />
    );
}

export const ShareToEarnVIPPage = () => {
  const [isModalVisible, setModalVisible] = useState(false);
  const [sharePageInfo, setSharePageInfo] = useState({});
  const { t } = useTranslation();

  useEffect(() => {
    loadData();
  }, []);

    const loadData = async () => {
        setSharePageInfo(await getSharePageInfo());
    };

  const handleButtonClick = () => {
    setModalVisible(true);
  };
  const handleInviteClick = () => {
    navigator.clipboard
      .writeText(sharePageInfo.share_link)
      .then(() => {
        alert(t("share_link_copied") + sharePageInfo.share_link);
      })
      .catch((err) => {
        alert(t("share_link_copy_failed") + sharePageInfo.share_link);
      });
  };

  const handleCloseModal = () => {
    setModalVisible(false);
  };

  return (
    <div style={{ backgroundColor: "#161823" }} className="h-screen">

      <div className="relative">
        <img src={share_bg} className="w-full" alt="" />
      </div>

        <p className="mt-5 bottom-6 w-full text-center text-white text-[16px]">
            {t("share_successfully_invited_part1")}
            <span className="text-carrot-slides-indicator-sel">
            {sharePageInfo.referrals}
          </span>
            {t("share_successfully_invited_part2")}
        </p>

        <div className="flex justify-center mt-[20px] items-center space-x-2">
            <img src={ic_welfare} className="w-8 h-8" alt="" />
            <p className="text-white">{t("share_rewardin_tasks1")}</p>
        </div>

        <div className="text-white text-center mt-[10px]" >
            <CustomText count={'+1'} />
        </div>

        <div className="h-0.5 bg-white mt-5 ml-5 mr-5 opacity-25">{/*占位的*/}</div>

        <div className="flex justify-center items-center mt-[20px] items-center space-x-2">
            <img src={ic_welfare} className="w-8 h-8" alt="" />
            <p className="text-white">{t("share_rewardin_tasks2")}</p>
        </div>

      {/*<div className="text-white text-center pt-[30px] pl-[42px] pr-[42px] pb-[25px]">
        {t("share_tips")}
      </div>*/}

      <div className="text-white text-center pl-[20px] pr-[20px]">

        <div className="w-fit text-left mx-auto p-5">
          <div>
            <span className="text-carrot-slides-indicator-sel pr-1">
              {t("share_class_1")}
            </span>
            {t("share_class_1_info")}
          </div>
          <div className="mt-5">
            <span className="text-carrot-slides-indicator-sel pr-1">
              {t("share_class_2")}
            </span>
            {t("share_class_2_info")}
          </div>
          <div className="mt-5">
            <span className="text-carrot-slides-indicator-sel pr-1">
              {t("share_class_3")}
            </span>
            {t("share_class_3_info")}
          </div>
          <div className="mt-5">
            <span className="text-carrot-slides-indicator-sel pr-1">
              {t("share_class_4")}
            </span>
            {t("share_class_4_info")}
          </div>
        </div>
      </div>

      <div
        style={{ backgroundColor: "#272932" }}
        className="h-px mt-[30px] ml-[20px] mr-[20px]"
      ></div>

      <div className="h-32">{/*占位的*/}</div>

      <div
        style={{ backgroundColor: "black" }}
        className="fixed bottom-0 bottom-[85px] left-0 w-full text-white h-20 py-4 space-x-4 pl-5 pr-5 flex justify-center items-center"
      >
        {sharePageInfo.my_invitation_code_from === null && (
          <button
            style={{ backgroundColor: "#7436FF", fontSize: "17px", flex: "3" }}
            className="px-4 py-3 rounded"
            onClick={handleButtonClick}
          >
            {t("share_fill_invitation_code")}
          </button>
        )}
        <button
          style={{ backgroundColor: "#F8A034", fontSize: "17px", flex: "3" }}
          className="px-4 py-3 rounded"
          onClick={handleInviteClick}
        >
          {t("share_invite_friend")}
        </button>
      </div>

      <InputInviteCodeDialog
        isVisible={isModalVisible}
        onClose={handleCloseModal}
        onSuccess={loadData} />
    </div>
  );
};
