import {useEffect, useState} from "react";
import {buyVip, getCurrentUserProfile, getVIPPriceList} from "../apis";
import {differenceInDays, parseISO} from "date-fns";
import {useTranslation} from "react-i18next";

export const RechargeVipPage = () => {
  const { t } = useTranslation();
  const [userData, setUserData] = useState({});
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [pageData, setPageData] = useState([

  ]);
  const [timeDiffer, setTimeDiffer] = useState({ diff: 0 });

  const handleCardClick = (index, plan) => {
    setSelectedIndex(index);
    // alert(plan.endSeconds)
  };

  const confirmClick = () => {
    buyVip(pageData[selectedIndex].id).then(res => {
      alert(t('purchase_success'));
    }).catch( e => {
      alert(e);
    })
  };

  useEffect(() => {
    const loadData = async () => {
      setUserData(await getCurrentUserProfile());
      setTimeDiffer({ diff: calculateDaysDifference(userData.vipEndTime) });
      setPageData(await getVIPPriceList())
    };
    loadData();
  }, [userData.vipEndTime]);

  const calculateDaysDifference = (dateString) => {
    if (!dateString) return 0;
    const date1 = parseISO(dateString);
    const date2 = new Date(); // 当前时间

    return differenceInDays(date1, date2);
  };

  return (
    <div className="bg-white h-screen">
      <div className="flex flex-col bg-carrot-gray-33 py-8 px-6 text-white">
        <p>Hi, {userData.nickName}</p>
        <p className="text-[#f59e0b] text-sm mt-[3px]">
          {timeDiffer.diff <= 0
            ? t("user_vip_expired")
            : t("user_vip_expired_left", { days: timeDiffer.diff })}
        </p>
      </div>

      <div className="p-[10px]">
        <p style={{ color: "#D4A057" }} className="px-[20px]">
          {t("user_upgrade_to_vip")}
        </p>
        <div className="bg-customGray mb-[35px] flex flex-col items-center p-4">
          {pageData.map((plan, index) => (
            <SubscriptionCard
              key={index}
              name={plan.name}
              price={plan.price}
              tag={plan.tag}
              endTime={plan.endSeconds === null ? 0 : plan.endSeconds}
              tips={plan.tips}
              isSelected={selectedIndex === index}
              onClick={() => handleCardClick(index, plan)}
            />
          ))}
        </div>
      </div>

      <div
        className="fixed flex bottom-[85px] text-[red] w-full bg-white h-[70px]"
        style={{
          boxShadow:
            "0 -4px 6px -1px " +
            "rgba(0, 0, 0, 0.1), 0 -2px 4px -1px rgba(0, 0, 0, 0.06)",
        }}
      >
        <div className="w-2/5 p-2 flex flex-col items-center justify-center text-black">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="size-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M8.625 9.75a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0H8.25m4.125 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0H12m4.125 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0h-.375m-13.5 3.01c0 1.6 1.123 2.994 2.707 3.227 1.087.16 2.185.283 3.293.369V21l4.184-4.183a1.14 1.14 0 0 1 .778-.332 48.294 48.294 0 0 0 5.83-.498c1.585-.233 2.708-1.626 2.708-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0 0 12 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018Z"
            />
          </svg>
          <p>{t('contact_kf')}</p>
        </div>
        <div
          onClick={confirmClick}
          style={{ color: "#FCF5E9" }}
          className="w-3/5 p-2 bg-customGold flex flex-col items-center justify-center"
        >
          <p className="text-2xl">$ {pageData[selectedIndex] ? pageData[selectedIndex].price : 0}</p>
          <p className="text-sm">{t('confirm_purchase')}</p>
        </div>
      </div>
    </div>
  );
};

const SubscriptionCard = ({
  name,
  price,
  tag,
  endTime,
  tips,
  isSelected,
  onClick,
}) => {
  const [time, setTime] = useState(endTime);

  useEffect(() => {
    if (time > 0) {
      const interval = setInterval(() => {
        setTime((prevTime) => prevTime - 1);
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [time]);

  const formatTime = (time) => {
    if (time <= 0) return "00:00:00";
    const hours = Math.floor(time / 3600)
      .toString()
      .padStart(2, "0");
    const minutes = Math.floor((time % 3600) / 60)
      .toString()
      .padStart(2, "0");
    const seconds = (time % 60).toString().padStart(2, "0");
    return `${hours}:${minutes}:${seconds}`;
  };

  return (
    <div
      onClick={onClick}
      className={`relative w-full p-6 rounded shadow-md border-2 mb-4 transition duration-300 ease-in-out ${isSelected ? "border-customGold" : "border-transparent"}`}
      style={{
        cursor: "pointer",
        backgroundColor: isSelected ? "#FCF5E9" : "#F6F6F6",
      }}
    >
      {tag && (
        <div
          style={{ backgroundColor: "#FF4D4F" }}
          className="absolute top-0 left-0 text-white text-xs font-bold px-2 py-1 mx-2 my-2 rounded"
        >
          {tag}
        </div>
      )}
      <div className="text-center">
        <h3 className="text-gray-700 text-lg mb-2">{name}</h3>
        <div className="text-[#C49967] text-4xl font-bold">
          <label className="text-2xl mr-[5px]">$</label>
          {price}
        </div>
        {time > 0 && (
          <div className="flex justify-center mt-4">
            <div
              style={{ backgroundColor: "#FF4D4F" }}
              className="text-white px-2 py-1 rounded mr-1"
            >
              {formatTime(time)}
            </div>
          </div>
        )}
        {tips && <p className="mt-[20px] text-customGold font-bold">{tips}</p>}
      </div>
    </div>
  );
};
