import { Link } from "react-router-dom";
export const Tab = ({ tabItems }) => {
  return (
    <div className="flex h-12 px-[30px] bg-black justify-between pt-[3px] pb-[34px] box-content">
      {tabItems &&
        tabItems.map((item) => (
          <Link
            className={`w-[30px] text-[9px] ${
              item.active ? "text-carrot-active" : "text-carrot-deactive"
            } text-center flex flex-col items-center`}
            key={item.label}
            to={item.route}
            preventScrollReset={true}
          >
            <img src={item.active ? item.icon_sel : item.icon_def} alt="" />
            <span>{item.label}</span>
          </Link>
        ))}
    </div>
  );
};
