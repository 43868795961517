import { createSlice } from "@reduxjs/toolkit";
import {store} from "../index";

const userJSON = localStorage.getItem("user");

const initialState = {
  user: userJSON ? JSON.parse(userJSON) : null,
  token: localStorage.getItem("token"),
  isLogin: false,
  favorites: null,
  watchHistory: null,
  purchasedVideos: null,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload.user;
      state.token = action.payload.token || state.token;
      localStorage.setItem("token", state.token);
      localStorage.setItem("user", JSON.stringify(state.user));
    },
    deleteUser: (state) => {
      // 清除用户信息和 token
      state.user = null;
      state.token = null;
      state.isLogin = false; // 如果需要，可以设置为 false
      state.favorites = null;
      state.watchHistory = null;
      state.purchasedVideos = null;

      // 从 localStorage 中删除
      localStorage.removeItem("token");
      localStorage.removeItem("user");

      const user = state.user;
      console.log('user:', user)
    },
    setVideoLists: (state, action) => {
      state.favorites = action.payload.favorites;
      state.watchHistory = action.payload.watchHistory;
      state.purchasedVideos = action.payload.purchasedVideos;
    },
    addVideoToFavorites: (state, action) => {
      if (state.favorites) {
        state.favorites.records = [
          action.payload,
          ...(state.favorites ? state.favorites.records : []),
        ];
        if (state.favorites?.records.length > 10) {
          state.favorites.records = [...state.favorites.records?.splice(0, 10)];
        }
      }
    },
    addVideoToWatchHistory: (state, action) => {
      if (state.watchHistory) {
        state.watchHistory.records = [
          action.payload,
          ...(state.watchHistory ? state.watchHistory.records : []),
        ];
        if (state.watchHistory?.records.length > 10) {
          state.watchHistory.records = [
            ...state.watchHistory.records?.splice(0, 10),
          ];
        }
      }
    },
    updatePurchasedVideosById: (state, action) => {
      const index = state.purchasedVideos.records.findIndex(
        (v) => v.id === action.payload.id,
      );
      state.purchasedVideos.records[index] = {
        ...state.purchasedVideos.records[index],
        ...action.payload,
      };
    },
  },
});

export default userSlice.reducer;
