import { store } from "../store";
import i18n from "../i18n";
import router from "../routes";

let BASE_URL = "";
// if (process.env.NODE_ENV === "production") {
//   BASE_URL = "http://3.25.203.173:8080";
// }

export const request = async (url, method = "get", options = {}) => {
  const token = store.getState().user.token;
  const defaultHeaders = {
    "Content-Type": "application/json",
    lang: i18n.language,
  };
  token && (defaultHeaders.Authorization = `Bearer ${token}`);

  const { headers } = options;

  options.headers = { ...defaultHeaders, ...headers };

  if (method === "post" && options.body) {
    options.body = JSON.stringify(options.body);
  }

  const response = await fetch(BASE_URL + url, {
    method,
    ...options,
  });

  if (!response.ok) {
    throw new Error(`Request failed with status ${response.status}`);
  }

  const result = await response.json();
  if (result.code === 401) {
    localStorage.removeItem("token");
    router.navigate(
      `/login?apiunauth=1&redirect=${router.state?.location.pathname}`,
    );
  } else if (result.code === 500) {
    throw new Error(result.message);
  }
  return result;
};

export const get = async (url, { params = {}, headers = {} } = {}) => {
  return request(
    url + (Object.keys(params).length === 0 ? "" : "?") + convertParams(params),
    "get",
    { headers },
  );
};

export const post = async (url, body, headers = {}) => {
  return request(url, "post", { body, headers });
};

const convertParams = (params) => {
  let urlParams = "";
  for (var key in params) {
    urlParams += `${key}=${params[key]}&`;
  }
  return urlParams.substring(0, urlParams.length - 1);
};
