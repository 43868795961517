import useTimer from "../hooks/useTimer";
import ic_mute_white from "../assets/video/ic_mute_white@3x.png";
import ic_next_huizhi from "../assets/profile/icon_next_huizhi@3x.png";
import { useCallback, useState } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { getVerificationCode, login, register } from "../apis";
import { useDispatch } from "react-redux";
import { setUser } from "../store/actions/userActions";
import { useTranslation } from "react-i18next";
import SelectPopUp from "../components/SelectPopUp";
export const LoginPage = () => {
  const defaultArea = process.env.REACT_APP_REGION === "china" ? "86": "91";
  const [areaCode, setAreaCode] = useState(defaultArea);
  const [isLoading, setIsLoading] = useState(false);
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [code, setCode] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname, search } = useLocation();

  const { seconds, isRunning, start } = useTimer({
    expireTime: 60,
    autostart: false,
    countdown: true,
  });

  const searchParam = new URLSearchParams(search);
  const redirectFromParam = searchParam.get("redirect");
  const apiunauth = searchParam.get("unauth");
  const sendVerification = async () => {
    if (isRunning) {
      return;
    }
    if (phone.trim() === "") {
      setErrMsg(t("username_empty_msg"));
      return;
    }
    start();
    try {
      await getVerificationCode({ phone, country: areaCode });
    } catch (error) {
      setErrMsg(error.message);
    }
  };

  const loginAct = async () => {
    if (phone.trim() === "") {
      setErrMsg(t("username_empty_msg"));
      return;
    }
    if (code.trim() === "" && pathname === "/register") {
      setErrMsg(t("code_empty_msg"));
      return;
    }
    if (password.trim() === "") {
      setErrMsg(t("passowrd_empty_msg"));
      return;
    }
    setErrMsg("");
    try {
      let res;
      setIsLoading(true);
      if (pathname === "/login") {
        res = await login({ phone, password, country: areaCode });
      } else {
        const promocode = localStorage.getItem("promocode");
        res = await register({
          phone,
          password,
          code,
          country: areaCode,
          promocode,
        });
      }
      if (res.code !== 500) {
        dispatch(setUser(res));
        let redirectURL = "/profile";
        redirectURL = redirectFromParam || redirectURL;
        navigate(redirectURL, { replace: true });
      } else {
        setErrMsg(res.message);
      }
    } catch (error) {
      setErrMsg(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const onSelectChange = useCallback((value) => {
    setAreaCode(value);
  }, []);

  return (
    <div className="px-3">
      <div className="w-11 h-11 p-3 -ml-4">
        <img
          src={ic_mute_white}
          alt=""
          onClick={() => {
            if (apiunauth === "1") {
              navigate("/index");
            } else {
              navigate(-1);
            }
          }}
        />
      </div>
      <h1 className="text-white text-4xl mt-6 mb-[60px]">
        {pathname === "/login" ? t("login") : t("register")}
      </h1>
      <div className="w-full flex flex-col justify-between space-y-6">
        <div className="px-3 h-12 w-full bg-carrot-inputbg flex items-center rounded">
          <div className="text-carrot-deactive text-[17px] h-[23px]">
            <SelectPopUp
              value={areaCode}
              onChange={onSelectChange}
              options={[
                { value: "86", label: "+86", flag: "🇨🇳", country: "China" },
                { value: "63", label: "+63", flag: "🇵🇭", country: "Philippine"},
                { value: "91", label: "+91", flag: "🇮🇳", country: "India" },
                { value: "55", label: "+55", flag: "🇧🇷", country: "Brazil" },
                { value: "33", label: "+33", flag: "🇫🇷", country: "French" },
                { value: "44", label: "+44", flag: "🇬🇧", country: "UK" },
              ]}
              optionEle={(option) => {
                return (
                  <div className="flex justify-between w-full px-10">
                    <div className="flex justify-end w-1/2">
                      <div>{option.country}</div>
                    </div>
                    <div className="flex justify-center w-1/2">
                      <div className="mr-2">{option.flag}</div>
                      <div>{option.label}</div>
                    </div>
                  </div>
                );
              }}
            />
          </div>
          <div className="w-full h-[23px] ml-3 pl-3 border-l border-l-carrot-deactive">
            <input
              type="number"
              className="w-full bg-transparent outline-0 text-white"
              placeholder={t("login_username_placeholder")}
              value={phone}
              onChange={({ target }) => {
                setPhone(target.value);
                setErrMsg("");
              }}
            />
          </div>
        </div>
        <div className="px-3 h-12 w-full bg-carrot-inputbg flex items-center rounded">
          <div className="w-full h-[23px]">
            <input
              type="password"
              className="w-full bg-transparent outline-0 text-white"
              placeholder={t("login_password_placeholder")}
              value={password}
              onChange={({ target }) => {
                setPassword(target.value);
                setErrMsg("");
              }}
            />
          </div>
        </div>
        {pathname === "/register" && (
          <div className="px-3 h-12 w-full bg-carrot-inputbg flex items-center rounded">
            <div className="w-full h-[23px] mr-3 pr-3 border-r border-r-carrot-slides-indicator-sel">
              <input
                type="text"
                className="w-full bg-transparent outline-0 text-white"
                placeholder={t("login_verification_placeholder")}
                value={code}
                onChange={({ target }) => {
                  setCode(target.value);
                  setErrMsg("");
                }}
              />
            </div>
            <div
              onClick={sendVerification}
              className={`${
                isRunning
                  ? "text-carrot-deactive"
                  : "text-carrot-slides-indicator-sel"
              } text-sm h-[23px] text-nowrap`}
            >
              {isRunning
                ? `${t("login_verification_wait_time", { seconds })}`
                : t("login_get_verification_code")}
            </div>
          </div>
        )}
      </div>
      {errMsg && <div className="text-white text-xs mt-3">{errMsg}</div>}
      <div className="flex justify-center mt-12">

        {isLoading ? (
            <svg
                className="animate-spin h-5 w-5 text-white mx-auto"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
            >
              <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
              ></circle>
              <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.96 7.96 0 014 12H0c0 2.294.962 4.373 2.621 5.937l1.379-1.646z"
              ></path>
            </svg>
        ) : (
            <img src={ic_next_huizhi} alt="" className="w-16" onClick={loginAct} />
        )}

      </div>
      {pathname === "/login" && (
        <div className="flex justify-center mt-5 text-carrot-gray underline">
          <Link to={"/register" + search}>{t("register_new_account")}</Link>
        </div>
      )}
    </div>
  );
};
