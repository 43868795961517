import { createAction, createSelector } from "@reduxjs/toolkit";
export const setHomeData = createAction("home/setHomeData", (payload) => ({
  payload,
}));
export const setCustomizedCategories = createAction(
  "home/setCustomizedCategories",
  (payload) => ({
    payload,
  }),
);
export const updateVideoById = createAction(
  "home/updateVideoById",
  (payload) => ({
    payload,
  }),
);
export const updateChannelVideos = createAction(
  "home/updateChannelVideos",
  (payload) => ({
    payload,
  }),
);

export const getCategories = createSelector(
  [
    (state) => {
      return state.home.categories;
    },
  ],
  (categories) => {
    return Object.values(categories).map(({ id, name }) => ({ id, name }));
  },
);

export const getChannelsById = createSelector(
  [
    (state) => {
      return state.home.categories;
    },
    (state, id) => {
      return id;
    },
  ],
  (categories, id) => {
    return categories[id]?.channels;
  },
);
