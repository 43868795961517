const colors = [
  '#FF5A00',
  '#F7802D',
  '#AC3EF9',
  '#F8A034'
]


export const getColor = () => {
  return colors[Math.floor(Math.random() * colors.length)]
}
