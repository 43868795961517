import React from "react";
import { Outlet } from "react-router-dom";
import { Tab } from "./Tab";

import tabConfig from "../config/tabconfig";
import useActiviteTab from "../hooks/useActiviteTab";

const TabBarLayout = () => {
  const tabItems = useActiviteTab(tabConfig);
  return (
    <div className="pb-16">
      <div className="pb-8">
        <Outlet />
      </div>
      <div className="fixed z-50 bottom-0 left-0 right-0">
        <Tab tabItems={tabItems}></Tab>
      </div>
    </div>
  );
};

export default TabBarLayout;
