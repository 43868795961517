import { createAction } from "@reduxjs/toolkit";
export const setArticleCategories = createAction(
  "article/setArticleCategories",
  (payload) => ({
    payload,
  }),
);
export const setArticleList = createAction(
  "article/setArticleList",
  (payload) => ({
    payload,
  }),
);
export const appendToArticleList = createAction(
  "article/appendToArticleList",
  (payload) => ({
    payload,
  }),
);
