import { Navigate, Outlet } from "react-router-dom";
import { useLocation } from "react-router-dom";

const ProtectedRoutes = () => {
  const { pathname } = useLocation();
  const localStorageToken = localStorage.getItem("token");
  return localStorageToken ? (
    <Outlet />
  ) : (
    <Navigate to={`/login?redirect=${pathname}`} replace />
  );
};

export default ProtectedRoutes;
