import { useEffect, useState } from "react";
import {getCurrentUserProfile, getRechargePriceList, createOrderForRecharge} from "../../apis";
import { TextMarquee } from "../../components/TextMarquee";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export const PuaPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
      document.title = "新的网页标题";
  }, []);

  return (
      <div className="bg-white h-screen">

      </div>
  );
};
