import ic_column_def from "../assets/tab/ic_column_def@3x.png";
import ic_column_sel from "../assets/tab/ic_column_sel@3x.png";
import ic_home_def from "../assets/tab/ic_home_def@3x.png";
import ic_home_sel from "../assets/tab/ic_home_sel@3x.png";

import ic_img_def from "../assets/tab/ic_img_def@3x.png";
import ic_img_sel from "../assets/tab/ic_img_sel@3x.png";


import ic_me_def from "../assets/tab/ic_me_def@3x.png";
import ic_me_sel from "../assets/tab/ic_me_sel@3x.png";
import i18n from "../i18n";

const tabConfig = [
  {
    icon_def: ic_home_def,
    icon_sel: ic_home_sel,
    route: "/index",
    label: i18n.t("tab_home"),
  },
  {
    icon_def: ic_column_def,
    icon_sel: ic_column_sel,
    route: "/articles",
    label: i18n.t("tab_articles"),
  }
];

process.env.REACT_APP_REGION === "china" && tabConfig.push({
  icon_def: ic_img_def,
  icon_sel: ic_img_sel,
  route: "/recharge",
  label: i18n.t("tab_recharge"),
});

tabConfig.push({
  icon_def: ic_me_def,
  icon_sel: ic_me_sel,
  route: "/profile",
  label: i18n.t("tab_profile"),
})

export default tabConfig;
