const pics = [
  'https://i.ebayimg.com/images/g/C0sAAOSwYIhWlqlK/s-l1600.jpg',
  'https://i.ebayimg.com/images/g/lVMAAOSwhQheYrmk/s-l1600.jpg',
  'https://mir-s3-cdn-cf.behance.net/project_modules/fs/b307d127256085.560502e8b209e.jpg',
  'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR4fGAF59MC4bwZ_xx2Atz87X5NcxFeZWce0v9rFkl6PQ&s',
  'https://m.media-amazon.com/images/I/61mTsKezTlL._AC_UF894,1000_QL80_DpWeblab_.jpg',
  'https://img.artpal.com/610421/5-19-7-1-16-56-24m.jpg',
  'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSbdlOSHP6xit7TQnVXs5WJSA6ABk6pJuMSpdp_EdjehQ&s',
  'https://mir-s3-cdn-cf.behance.net/project_modules/max_1200/e2fd9882892035.5d2c3c960586e.jpg',
  'https://blogs.illinois.edu/files/6231/806965/168440.jpg',
  'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQrJ0Fz0-GQWKGSUgPDCF9FuJgND8oYtoi9aznDANIHIg&s',
  'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQl8IbfLkCI-Gp1D7bam7oL3Yg9SkC6FtM4im_TgXxCEw&s',
  'https://i.pinimg.com/736x/22/db/d2/22dbd28d11c9aaeff3cc806925f9aed3.jpg',
  'https://lh5.googleusercontent.com/proxy/eR_o0uOsYCg-Tga-2Cvd0nAtowuSUEFnFdK-qVInTODAvMFRiUCCHh-yCgxIolBiK20KYN7-TUCPuj6vVVafllALftOzdetl9rA-JXHuAZcCELeLIxrnHQ',
  'https://lh6.googleusercontent.com/B0uQRPzgf2AX6EVvlqNWwV7ql2-TbAuWbJcnkHuC_uzEtG0viA6__4_v-bfdas4iM8MqIm8wcoDeaLRxugVFycbVvaelnagmzAsH9-ug-y0X2BxvEjFfhavM_fvWHDEnNKBFamG9=s0'
]

export const getPic = () => {
  return pics[Math.floor(Math.random() * pics.length)]
}