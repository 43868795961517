import { createSlice } from "@reduxjs/toolkit";
import { produce } from "immer";

let history = localStorage.getItem("searchHistory");
history = history ? JSON.parse(history) : [];

const initialState = {
  history,
  searchResult: [],
};

export const searchHistory = createSlice({
  name: "searchHistory",
  initialState,
  reducers: {
    pushToHistory: (state, action) => {
      if (!state.history.find((v) => v === action.payload)) {
        state.history = [action.payload, ...state.history];
        localStorage.setItem("searchHistory", JSON.stringify(state.history));
      }
    },
    clearHistory: (state) => {
      state.history = [];
    },
    setSearchResult: (state, action) => {
      state.searchResult = action.payload;
    },
    updateResultById: (state, action) => {
      const updateVideosInState = (state, videoId, updatedVideo) => {
        const newState = produce(state, (draft) => {
          const videoIndex = draft.searchResult.findIndex(
            (video) => video.id === videoId,
          );
          if (videoIndex !== -1) {
            draft.searchResult[videoIndex] = updatedVideo;
          }
        });
        return newState;
      };

      const updatedVideo = {
        ...state.searchResult.find((video) => video.id === action.payload.id),
        ...action.payload,
      };
      return {
        ...state,
        ...updateVideosInState(state, action.payload.id, updatedVideo),
      };
    },
  },
});

export default searchHistory.reducer;
