import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import PullToRefresh from "react-simple-pull-to-refresh";
import { getHomePageData, nextGroupByChannelId } from "../apis";
// import logo from "../assets/home/ic_logo_def@3x.png";
import ic_refresh from "../assets/home/ic_refresh_def@3x.png";
import ic_search from "../assets/home/ic_search_def@3x.png";
import { CardGrid } from "../components/CardGrid";
import { Categories } from "../components/Categories";
import NoticeDialog from "../components/dialogs/NoticeDialog";
import { Slides } from "../components/Slides";
import { BANNER_ADV_POS, CHANNEL_ADV_POS } from "../config";
import { getAdvByPosition, setNoticeShowed } from "../store/actions/advsAction";
import { jumpUrl } from "../utils";

import { Card } from "../components/Card";
import {
  getCategories,
  getChannelsById,
  setHomeData,
  updateChannelVideos,
} from "../store/actions/homeActions";


const logo = require(`../assets/home/${process.env.REACT_APP_REGION}/logo.png`);

export const HomePage = () => {
  const { cateid = "1" } = useParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const bannerADV = useSelector((state) =>
    getAdvByPosition(state, BANNER_ADV_POS),
  );
  const categories = useSelector((state) => getCategories(state));
  const { myCates } = useSelector((state) => state.home.customizedCategories);
  const channels = useSelector((state) => getChannelsById(state, cateid));
  const channelADV = useSelector((state) =>
    getAdvByPosition(state, CHANNEL_ADV_POS),
  );
  const notifications = useSelector((state) => state.advs.notifications);
  const [isShowDialog, setShowDialog] = useState();
  const isNoticeShowed = useSelector((state) => state.advs.isNoticeShowed);
  const [channelLoading, setChannelLoading] = useState({});
  const loadData = useCallback(async () => {
    try {
      const res = await getHomePageData();
      dispatch(setHomeData({ categories: res }));
    } catch (e) {}
  }, [dispatch]);
  const handleButtonClick = () => {};

  const nextGroup = useCallback(
    async (id) => {
      try {
        setChannelLoading((channelLoading) => ({
          ...channelLoading,
          [id]: true,
        }));
        const res = await nextGroupByChannelId(id);
        dispatch(updateChannelVideos({ cateid, id, videos: res }));
        setChannelLoading((channelLoading) => ({
          ...channelLoading,
          [id]: false,
        }));
      } catch (error) {}
    },
    [dispatch, cateid],
  );
  useEffect(() => {
    if (categories.length <= 0) {
      loadData();
    }
  }, [categories, loadData]);
  const jumpUrlById = useCallback(
    (id) => {
      const { jump_url } = bannerADV.find((v) => v.id === id);
      jumpUrl(jump_url);
    },
    [bannerADV],
  );

  return (
    <div>
      <div className="flex flex-col bg-carrotbg px-3">
        {!isNoticeShowed && (
          <>
            {/*/!* @TODO 这里把notice.position=0那个对象传进去*!/*/}
            <NoticeDialog
              noticeObj={notifications.find((v) => v.position === 0)}
              onClose={() => {
                setShowDialog(true);
              }}
            />
            {/*/!* @TODO 这里把notice.position=2那个对象传进去*!/*/}
            {isShowDialog && (
              <NoticeDialog
                noticeObj={notifications.find((v) => v.position === 2)}
                onClose={() => {
                  dispatch(setNoticeShowed());
                }}
              />
            )}
          </>
        )}

        <div className="flex">
          <div className="logo py-1 flex items-center">
            <img
              onClick={handleButtonClick}
              src={logo}
              className="max-h-11"
              alt=""
            />
          </div>
          <Link
            to="/search"
            className="searchbox rounded-full grow h-8 ml-5 my-1.5 bg-carrot-inputbg flex justify-center items-center"
          >
            <img src={ic_search} alt="" width="18" />
            <span className="ml-2 text-carrot-deactive text-nowrap text-ellipsis max-w-[50%] overflow-hidden">
              {t("search_placeholder")}
            </span>
          </Link>
        </div>

        {/* @TODO 这里把notice.position=1那个对象传进去*/}
        <NoticeDialog noticeObj={notifications.find((v) => v.position === 1)} />

        <div className="grow min-h-full text-carrot-deactive">
          <Categories
            activeItemClassName={"text-xl text-carrot-slides-indicator-sel"}
            categoryItems={myCates || categories}
            moreAction={() => {
              navigate("/categories");
            }}
            currentItemId={cateid}
            jumpAction={(id) => {
              navigate(`/index/${id}`);
            }}
          />
          <PullToRefresh onRefresh={loadData}>
            <div className="slides">
              <Slides items={bannerADV} jumpAction={jumpUrlById} />
            </div>
            {channels &&
              Object.values(channels).map((channel, i) => {
                return (
                  <div key={channel.id}>
                    <CardGrid
                      layoutType={channel.style}
                      items={Object.values(channel.videos)}
                      heading={channel.name}
                      moreAction={() => {
                        navigate(`/videolist/channel/${channel.id}`);
                      }}
                      jumpAction={(id) => {
                        navigate(`/video/${id}`, { state: { from: "/index" } });
                      }}
                    />
                    {channel?.videos &&
                      Object.keys(channel.videos).length > 0 &&
                      channel.style !== 1 && (
                        <div
                          className="flex justify-center items-center text-carrot-yellow"
                          onClick={() => {
                            nextGroup(channel.id);
                          }}
                        >
                          <img
                            src={ic_refresh}
                            alt=""
                            className={`h-3.5 w-3.5 mr-1 ${channelLoading[channel.id] ? "animate-spin" : ""}`}
                          />
                          {t("channel_getnewset")}
                        </div>
                      )}
                    {i === 2 && channels.length >= 3 && (
                      <div className="my-3">
                        <Card
                          type="ad"
                          thum={channelADV[0]?.pic_url}
                          name={channelADV[0]?.title}
                          jumpAction={() => jumpUrl(channelADV[0].jump_url)}
                        />
                      </div>
                    )}
                  </div>
                );
              })}
          </PullToRefresh>
        </div>
      </div>
    </div>
  );
};
