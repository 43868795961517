import ic_more from "../assets/home/ic_more_def@3x.png";
import { useTranslation } from "react-i18next";
export const Heading = ({ title, more, className, count, moreIconOnly }) => {
  const { t } = useTranslation();
  return (
    <div className={`flex text-white justify-between ${className}`}>
      <div className="text-[17px] font-medium">
        {title}
        <span className="text-sm text-carrot-gray-50 ml-2">{count}</span>
      </div>
      {typeof more === "function" && (
        <div className="flex items-center" onClick={more}>
          {moreIconOnly ? "" : t("channel_more")}
          <img src={ic_more} alt="" className="w-2 h-3 ml-1.5 mr-3" />
        </div>
      )}
    </div>
  );
};
