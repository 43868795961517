import { useState } from "react";
import { useSelector } from "react-redux";
import DialogBase from "./DialogBase";
import bg_popup_head from "../../assets/popup/bg_popup_head.png";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { buyVideo } from "../../apis";

const VideoPreviewDialog = ({ resultObj, videoObj, refreshFunction }) => {
  const [isOpen, setOpen] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const user = useSelector((state) => state.user);
  const curBalance = user.user ? user.user.balance : 0;

  function jumpTo(jump_url) {
    if (jump_url.startsWith("http")) {
      window.open(jump_url); // 通过浏览器打开URL
    } else {
      navigate(jump_url);
    }
    setOpen(false);
  }

  function loginAndClose() {
    jumpTo(`/login?redirect=/video/${videoObj.id}`);
    setOpen(false);
  }

  function upgradeToVIP() {
    jumpTo("/rechargeVIP");
    setOpen(false);
  }

  async function purchaseOrRecharge() {
    if (curBalance < videoObj.price) {
      // 充值
      jumpTo("/recharge");
      setOpen(false);
    } else {
      // 购买
      setIsLoading(true);

      try {
        await buyVideo(videoObj.id);
        //   .then((res) => {
        //   // 购买成功
        refreshFunction(); // 刷新页面
        // });
      } catch (e) {
      } finally {
        setIsLoading(false);
      }
      //   .catch(e => {
      //   alert(JSON.stringify(e))
      // }).finally(() => {
      //   setIsLoading(false);
      // })
    }
  }

  const loaderStyle = {
    border: "4px solid rgba(255, 255, 255, 0.3)",
    borderRadius: "50%",
    borderTop: "4px solid white",
    width: "20px",
    height: "20px",
    animation: "spin 1s linear infinite",
  };

  const buttonStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  const spinKeyframes = `
    @keyframes spin {
      0% { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
    }
  `;

  return (
    <div>
      <style>{spinKeyframes}</style>
      <DialogBase
        isOpen={isOpen}
        onMaskClick={() => {
          setOpen(false);
        }}
      >
        <div className="w-5/6 bg-white relative py-4 px-8 rounded-lg shadow-lg">
          <div className="absolute -top-12 left-1/2 transform -translate-x-1/2">
            <div className="rounded-full w-24">
              <img src={bg_popup_head} alt="Icon" />
            </div>
          </div>
          <div className="flex flex-col h-full">
            <div className="flex-1 overflow-y-auto mt-10">
              <div>{resultObj.message}</div>

              {resultObj.code === 1001 ? (
                <div>
                  <div>
                    {t("current_balance")}: ${curBalance}
                  </div>
                  <div>
                    {t("video_selling_price")}: ${videoObj.price}
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>

            <div className="pb-2 mt-2">
              {resultObj.code === 1001 ? (
                <div className="flex pb-2">
                  <button
                    onClick={() => setOpen(false)}
                    className="flex-1 bg-carrot-gray hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                    style={{ flex: "1" }}
                  >
                    x
                  </button>

                  <button
                    onClick={purchaseOrRecharge}
                    className="flex-4 bg-carrot-slides-indicator-sel text-white p-2 rounded ml-3"
                    style={{ ...buttonStyle, flex: "4" }}
                  >
                    {isLoading ? (
                      <div style={loaderStyle}></div>
                    ) : curBalance < videoObj.price ? (
                      t("recharge_now")
                    ) : (
                      t("confirm_purchase")
                    )}
                  </button>
                </div>
              ) : (
                ""
              )}

              {resultObj.code === 1002 ? (
                <button
                  onClick={loginAndClose}
                  className="w-full bg-carrot-slides-indicator-sel text-white p-2 rounded"
                >
                  {t("confirm")}
                </button>
              ) : (
                ""
              )}

              {resultObj.code === 1003 ? (
                <button
                  onClick={upgradeToVIP}
                  className="w-full bg-carrot-slides-indicator-sel text-white p-2 rounded"
                >
                  {t("upgrade_to_vip")}
                </button>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </DialogBase>
    </div>
  );
};

export default VideoPreviewDialog;
