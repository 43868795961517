import { useState } from "react";
import DialogBase from "./DialogBase";
import { useTranslation } from "react-i18next";
import bg_popup_head from "../../assets/popup/bg_popup_head.png";

const ConfirmDialog = ({ onConfirm, onClose }) => {
  const [isOpen, setOpen] = useState(true);
  const { t } = useTranslation();

    const closeDialog = () => {
        setOpen(false);
        onClose();
    };

  const spinKeyframes = `
    @keyframes spin {
      0% { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
    }
  `;

  return (
      <div>
        <style>{spinKeyframes}</style>
        <DialogBase
            isOpen={isOpen}
            onMaskClick={() => {
                closeDialog();
            }}>

            <div className="w-5/6 bg-white relative py-4 px-8 rounded-lg shadow-lg">
                <div className="absolute -top-12 left-1/2 transform -translate-x-1/2">
                    <div className="rounded-full w-24">
                        <img src={bg_popup_head} alt="Icon" />
                    </div>
                </div>
                <div className="flex flex-col h-full">
                    <div className="flex-1 overflow-y-auto mt-10">
                        <div>{t("confirm_title")}</div>
                    </div>

                    <div
                        className="bottom-0 left-0 w-full text-white h-20 py-4 space-x-4 pl-5 pr-5 flex justify-center items-center">
                        <button
                            style={{ fontSize: "17px", flex: "2" }}
                            className="px-4 py-3 rounded bg-carrot-gray"
                            onClick={onClose}>
                            {t("cancel")}
                        </button>
                        <button
                            style={{ backgroundColor: "#F8A034", fontSize: "17px", flex: "2" }}
                            className="px-4 py-3 rounded"
                            onClick={onConfirm}>
                            {t("confirm")}
                        </button>
                    </div>

                </div>
            </div>

        </DialogBase>
      </div>
  );
};

export default ConfirmDialog;
