import { useLocation } from "react-router-dom";
const useActiviteTab = (tabItems) => {
  const routeInfo = useLocation();
  const result = tabItems.map((item) => {
    if (routeInfo.pathname === "/" || item.route === "/") {
      item.active = routeInfo.pathname === item.route;
    } else {
      item.active = routeInfo.pathname.startsWith(item.route);
    }
    return { ...item };
  });
  return result;
};

export default useActiviteTab;

