import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getHotKeyword, searchVideo } from "../apis";
import bg_search_empty from "../assets/home/bg_search_empty@3x.png";
import ic_search from "../assets/home/ic_search_def@3x.png";
import ic_mute_white from "../assets/video/ic_mute_white@3x.png";
import { CardGrid } from "../components/CardGrid";
import { getColor } from "../config/randomColor";
import { clearHistory, pushToHistory } from "../store/actions/searchHistory";
export const SearchPage = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [keyword, setInputKeyword] = useState(
    searchParams.get("keyword") || "",
  );
  const [pageNum, setPageNum] = useState(1);
  const [loading, setLoading] = useState(false);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [searchResult, setSearchResult] = useState([]);
  const [showSearchResult, setShowSearchResult] = useState(false);
  const [hotKeyword, setHotKeyword] = useState([]);
  const searchHistory = useSelector((state) => state.searchHistory.history);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const loadResult = async (keyword, pageNum = 1) => {
    try {
      setLoading(true);
      const res = await searchVideo(keyword, pageNum, 10);
      setSearchResult((searchResult) => [...searchResult, ...res.records]);
      setLoading(false);
      setHasNextPage(res.records?.length >= 10);
    } catch (error) {}
  };

  const search = useCallback(
    (keyword) => {
      setPageNum(1);
      loadResult(keyword);
      setShowSearchResult(true);
      dispatch(pushToHistory(keyword));
      setSearchParams({ keyword });
    },
    [dispatch, setSearchParams],
  );

  const loadMore = () => {
    loadResult(keyword, pageNum + 1);
    setPageNum((pageNum) => pageNum + 1);
  };

  const [sentryRef] = useInfiniteScroll({
    onLoadMore: loadMore,
    loading,
    hasNextPage,
    rootMargin: "0px 0px 300px 0px",
  });

  const keyWordClick = useCallback(
    (keyword) => {
      setInputKeyword(keyword);
      search(keyword);
    },
    [search],
  );
  useEffect(() => {
    (async () => {
      try {
        const res = await getHotKeyword();
        setHotKeyword(res.map((v) => ({ ...v, color: getColor() })));
      } catch (error) {}
    })();
  }, []);
  useEffect(() => {
    if (!keyword) {
      setShowSearchResult(false);
    }
  }, [keyword]);

  useEffect(() => {
    const keywordFromParam = searchParams.get("keyword");
    if (keywordFromParam) {
      dispatch(pushToHistory(keywordFromParam));
      search(keywordFromParam);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <div>
      <div className="px-2  z-10 w-full border-b border-b-carrot-deactive bg-carrotbg">
        <div className="flex">
          <img
            className="w-11 h-11 p-3 -ml-4 "
            src={ic_mute_white}
            alt=""
            onClick={() => {
              navigate("/index");
            }}
          />
          <div className="searchbox rounded-full grow h-8 my-1.5 bg-carrot-inputbg flex items-center px-2">
            <img src={ic_search} alt="" width="18" />
            <span className="ml-2 text-carrot-deactive text-nowrap text-ellipsis w-full overflow-hidden flex items-center">
              <input
                type="text"
                placeholder={t("search_placeholder")}
                className="w-full bg-transparent outline-0 text-white text-sm"
                value={keyword}
                onChange={(e) => setInputKeyword(e.target.value)}
              />
            </span>
          </div>
          <button
            type="button"
            className="text-carrot-slides-indicator-sel text-base pl-3"
            onClick={() => {
              search(keyword);
            }}
          >
            {t("search_button")}
          </button>
        </div>
      </div>
      {showSearchResult &&
        (searchResult.length === 0 ? (
          <div className="w-full h-full flex flex-wrap justify-center pt-[114px] items-start content-start">
            <img src={bg_search_empty} alt="" className="w-44 h-44" />
            <div className="w-full text-center text-carrot-deactive text-sm">
              {t("search_empty")}
            </div>
          </div>
        ) : (
          <div className="px-3 pb-2">
            <CardGrid items={searchResult} cardDirection={"horizontal"} />
            {(loading || hasNextPage) && (
              <div ref={sentryRef} className="text-white text-center h-7">
                {t("video_loading")}
              </div>
            )}
          </div>
        ))}
      {!showSearchResult && (
        <>
          <div className="px-3">
            <div className="text-white text-sm my-3">{t("search_history")}</div>
            <div className="flex flex-wrap justify-start text-base">
              {searchHistory.map((v) => (
                <div
                  key={v}
                  className="bg-carrot-inputbg rounded-full px-3 py-1 text-white mb-3 mr-2"
                  onClick={() => {
                    keyWordClick(v);
                  }}
                >
                  {v}
                </div>
              ))}
            </div>
            <div className="px-3 flex justify-center my-5">
              <button
                type="button"
                onClick={() => {
                  dispatch(clearHistory());
                }}
                className="text-sm text-carrot-gray"
              >
                {t("search_clean_history")}
              </button>
            </div>
          </div>
          <div className="px-3 border-t border-t-carrot-deactive">
            <div className="text-white text-sm my-3">{t("search_hot")}</div>
            <div className="flex flex-wrap justify-start text-base">
              {hotKeyword.map((v) => (
                <div
                  key={v.id}
                  className={`bg-[${v.color}] rounded-full px-3 py-1 text-white mb-3 mr-2`}
                  onClick={() => {
                    keyWordClick(v.keyword);
                  }}
                >
                  # {v.keyword}
                </div>
              ))}
            </div>
          </div>
        </>
      )}
    </div>
  );
};
