import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper/modules";
import "swiper/css";

export const Slides = ({ items, jumpAction }) => {
  const pagination = {
    clickable: false,
    bulletClass: "w-1 h-1 mr-1 rounded-full bg-carrot-slides-indicator-def",
    bulletActiveClass: "w-2 bg-carrot-slides-indicator-sel",
    horizontalClass: "slides-pagination",
    renderBullet: function (index, className) {
      return '<div class="' + className + '"></div>';
    },
  };
  return (
    <>
      <Swiper
        className="h-[180px] relative rounded fixhidden"
        loop={true}
        pagination={pagination}
        modules={[Pagination, Autoplay]}
        slidesPerView={1}
        spaceBetween={50}
        autoplay={{
          delay: 3000,
        }}
      >
        {items &&
          items.map((v) => (
            <SwiperSlide className="relative" key={v.id}>
              <div onClick={() => jumpAction && jumpAction(v.id)}>
                <img
                  src={v.pic_url}
                  alt=""
                  className="rounded w-full h-[180px] object-cover"
                />
                <div className="absolute bottom-0 left-0 px-3 pt-3 w-full h-[46px] overflow-hidden text-ellipsis text-nowrap text-white bg-gradient-to-t from-black">
                  {v.title}
                </div>
              </div>
            </SwiperSlide>
          ))}
      </Swiper>
    </>
  );
};
