import { useEffect, useState } from "react";
import {getCurrentUserProfile, getRechargePriceList, createOrderForRecharge} from "../apis";
import { TextMarquee } from "../components/TextMarquee";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export const RechargePage = () => {
  const { t } = useTranslation();
  const [userData, setUserData] = useState({});
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const navigate = useNavigate();

  const handleCardClick = (index) => {
    setSelectedIndex(index);
  };

  const balanceDetails = () => {
    navigate(`/balanceDetails`);
  }

  const confirmClick = () => {
    if(isLoading) return;
    setIsLoading(true);
    createOrderForRecharge(options[selectedIndex].id).then(res => {
      // alert(t('purchase_success'));
      const data = JSON.parse(res.data);
      if(data.retCode !== '0') {
        alert(data.retMsg);
      } else {
        window.location.href = data.payParams.payUrl;
      }
      setIsLoading(false);
    }).catch( e => {
      alert(e);
      setIsLoading(false);
    })
  };

  useEffect(() => {
    const loadData = async () => {
      try {
        const profile = await getCurrentUserProfile();
        setUserData(profile);
        setOptions(await getRechargePriceList())
      } catch (error) {
        alert(error);
      }
    };
    loadData();
  }, []);

  return (
    <div className="bg-white h-screen">
      <TextMarquee text="这是一个跑马灯效果的文字，可以从右往左移动。" />

      <div className="flex flex-col bg-carrot-gray-33 py-8 px-6 text-white">
        <div className="flex justify-between items-center">
          <p>
            {t("current_balance")}:{" "}
            <label
                style={{ backgroundColor: "#FF4D4F" }}
                className="text-white font-bold px-2 py-1 mx-2 my-2 rounded">
              $ {userData.balance}
            </label>
          </p>
          <button
              className="text-carrot-highres-bg underline hover:text-blue-300"
              onClick={balanceDetails}
              style={{ backgroundColor: "transparent", border: "none", padding: "0", margin: "0" }}>
            {t("balance_stat")}
          </button>
        </div>
      </div>

      {/*<div className="flex w-full h-14">
        <button className="w-1/2 text-black py-2 border-b-4 border-yellow-light">
          普通充值
        </button>
        <button className="w-1/2 text-black py-2">USDT 充值</button>
      </div>*/}

      <div className="p-[10px]">
        <div className="grid grid-cols-3">
          {options.map((option, index) => (
            <PriceOption
              key={index}
              price={option.price}
              description={option.description}
              isSelected={selectedIndex === index}
              onClick={() => handleCardClick(index)}
            />
          ))}
        </div>
      </div>

      <div
        className="fixed flex bottom-[85px] text-[red] w-full bg-white h-[70px]"
        style={{
          boxShadow:
            "0 -4px 6px -1px " +
            "rgba(0, 0, 0, 0.1), 0 -2px 4px -1px rgba(0, 0, 0, 0.06)",
        }}
      >
        <div className="w-2/5 p-2 flex flex-col items-center justify-center text-black">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="size-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M8.625 9.75a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0H8.25m4.125 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0H12m4.125 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0h-.375m-13.5 3.01c0 1.6 1.123 2.994 2.707 3.227 1.087.16 2.185.283 3.293.369V21l4.184-4.183a1.14 1.14 0 0 1 .778-.332 48.294 48.294 0 0 0 5.83-.498c1.585-.233 2.708-1.626 2.708-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0 0 12 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018Z"
            />
          </svg>
          <p>{t('contact_kf')}</p>
        </div>
        <div
          onClick={confirmClick}
          style={{ color: "#FCF5E9" }}
          className="w-3/5 p-2 bg-carrot-highres-bg flex flex-col items-center justify-center">

          {isLoading ? (
              <svg
                  className="animate-spin h-5 w-5 text-white mx-auto"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
              >
                <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                ></circle>
                <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.96 7.96 0 014 12H0c0 2.294.962 4.373 2.621 5.937l1.379-1.646z"
                ></path>
              </svg>
          ) : <div>
            <p className="text-2xl">¥ {options[selectedIndex] ? options[selectedIndex].price : 0}</p>
            <p className="text-sm">{t('confirm_recharge')}</p>
          </div>}


        </div>
      </div>
    </div>
  );
};

const PriceOption = ({ price, description, isSelected, onClick }) => {
  return (
    <div
      onClick={onClick}
      className={`border m-1 rounded text-center ${
        isSelected
          ? "bg-yellow-light-bg border-yellow-border"
          : "gray-light-border"
      }`}
    >
      <div className="text-xl font-bold mb-2 mx-5 mt-3">¥{price}</div>
      <div
        className={`${
          isSelected ? "bg-yellow-light" : "bg-gray-light"
        } text-[#333] text-sm`}
      >
        {description}
      </div>
    </div>
  );
};
