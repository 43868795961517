import {useCallback, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {Link, useNavigate} from "react-router-dom";
import {getCollectionAndWatchHistory, getCSLink, getCurrentUserProfile} from "../apis";
import ic_feedback from "../assets/profile/ic_feedback_def@3x.png";
import ic_head_def from "../assets/profile/ic_head_def@3x.png";
import ic_integral from "../assets/profile/ic_integral_arrow@3x.png";
import ic_kf from "../assets/profile/ic_kf.png";
import ic_vip from "../assets/profile/ic_vip.png";
import {CardGrid} from "../components/CardGrid";
import {PROFILE_ADV_POS} from "../config";
import {getAdvByPosition} from "../store/actions/advsAction";
import {setUser, setVideoLists, deleteUser} from "../store/actions/userActions";
import {jumpUrl} from "../utils";
import PullToRefresh from "react-simple-pull-to-refresh";
import ConfirmDialog from "../components/dialogs/ConfirmDialog";
import InputFeedbackDialog from "../components/dialogs/InputFeedbackDialog";

export const ProfilePage = () => {
  const dispatch = useDispatch();
  const [isLogoutVisible, setLogoutVisible] = useState(false);
  const [isFeedbackVisible, setFeedbackVisible] = useState(false);
  const [CSLink, setCSLink] = useState('');
  const navigate = useNavigate();

  const handleLogoutCloseModal = () => {
    setLogoutVisible(false);
  };

  const handleLogoutConfirm = () => {
    handleLogoutCloseModal();
/*    localStorage.removeItem('token')
    localStorage.removeItem('user')*/

    dispatch(deleteUser());

    navigate("/index");
  };

  const handleFeedbackOpenModal = () => {
    setFeedbackVisible(true);
  };

  const handleFeedbackCloseModal = () => {
    setFeedbackVisible(false);
  };

  const handleLogoutOpenModal = () => {
    setLogoutVisible(true);
  }

  const openCSLink = () => {
    window.open(CSLink);
  }

  const { user, favorites, watchHistory, purchasedVideos } = useSelector(
    (state) => state.user,
  );
  const advs = useSelector((state) => getAdvByPosition(state, PROFILE_ADV_POS));
  const { t } = useTranslation();
  const loadData = useCallback(async () => {
    dispatch(setUser({ user: await getCurrentUserProfile() }));
    const { watch_history, collect, videos_purchased } =
      await getCollectionAndWatchHistory();
    dispatch(
      setVideoLists({
        watchHistory: watch_history,
        favorites: collect,
        purchasedVideos: videos_purchased,
      }),
    );

    getCSLink().then(res => {
      setCSLink(res.data);
    });

  }, [dispatch]);
  useEffect(() => {
    if (!favorites) {
      loadData();
    }
  }, [favorites, loadData]);
  return (
    <PullToRefresh onRefresh={loadData}>
      <div className="relative">
        <img
          src={advs[0]?.pic_url}
          alt=""
          className="w-full aspect-[15/7] object-cover"
          onClick={() => {
            advs[0]?.jump_url && jumpUrl(advs[0].jump_url);
          }}
        />
        {/*<div className="absolute right-5 top-[61px] text-white text-base">
          {t("profile_setting")}
        </div>*/}
      </div>
      <div className="px-3">
        <div className="flex">
          <div className="text-white basis-1/2 -mt-[55px] z-10 mx-1">
            <img src={ic_head_def} className="w-[110px]" alt="" />
            <div className="text-2xl mt-2.5 mb-2 font-medium pl-3">
              {user?.nickName}
            </div>
          </div>
          {/*<div className="flex h-11 mt-6 px-2 bg-carrotbg-2 justify-end items-center rounded">
            <img src={ic_gift} alt="" className="w-6 h-6" />
            <Link to={`/shareToEarnVIP`}>
              <div className="text-white text-sm">
                {t("profile_share_get_membership")}
              </div>
            </Link>
          </div>*/}
        </div>
        <div className="mt-4">
          <div className="flex items-center w-full justify-start">
            <div className="flex bg-carrot-gray-100 py-1 px-2.5 rounded-full items-center justify-between">
              {t("profile_balance")}
              <span className="mx-2">$&nbsp;{user?.balance}</span>
              <img src={ic_integral} className="w-1 h-1.5" alt="" />
            </div>
            <div className="text-carrot-deactive ml-2">
              {user?.vipLeftDays < 0 ? ( t("profile_membership_expired") ) :
              (
                  <div>
                    {t("profile_membership_days_remain")}
                    <span className="pl-1 text-carrot-slides-indicator-sel">
                    {user?.vipLeftDays}
                  </span>
                  </div>
              )}
            </div>

            <div className="text-carrot-deactive ml-2">
              {user?.vipLeftDays < 0 ? ( t("free_count")+": "+user?.watchLeftToday+" / "+user?.watchFreeCount) : ""}
            </div>

          </div>
        </div>
        <div className="flex justify-between px-2 mt-8 py-3 text-sm">
          <div className="flex flex-col justify-center items-center text-white" onClick={openCSLink}>
            <img src={ic_kf} className="w-12" alt="" />
            {t("profile_communicate_support")}
          </div>
          <Link to={`/shareToEarnVIP`}>
            <div className="flex flex-col justify-center items-center text-white">
              <img src={ic_vip} className="w-12" alt="" />
              {t("profile_award")}
            </div>
          </Link>
          {/*<Link to={`/recharge`}>
            <div className="flex flex-col justify-center items-center text-white">
              <img src={ic_download} className="w-12" alt="" />
              {t("profile_topup")}
            </div>
          </Link>*/}
          <div className="flex flex-col justify-center items-center text-white" onClick={handleFeedbackOpenModal}>
            <img src={ic_feedback} className="w-12" alt="" />
            {t("profile_feedback")}
          </div>
        </div>
        {/* <div className="my-3"> */}
        {/*   <img src={img_banner_me} alt="" /> */}
        {/* </div> */}
        <CardGrid
          layoutType={1}
          heading={t("watch_history")}
          moreAction={() => {
            navigate("/videolist/history");
          }}
          items={watchHistory?.records}
        />
        <CardGrid
          layoutType={2}
          heading={t("my_favourite")}
          items={favorites?.records}
          moreAction={() => {
            navigate("/videolist/favorite");
          }}
        />
        <CardGrid
          layoutType={1}
          heading={t("purchased_videos")}
          moreAction={() => {
            navigate("/videolist/history");
          }}
          items={purchasedVideos?.records}
        />

        <button style={{ fontSize: "17px" }} className="bg-carrot-gray-100 w-full h-12 rounded text-carrot-gray mt-10 opacity-90"
                onClick={handleLogoutOpenModal}>
          {t("user_logout")}
        </button>

        {isLogoutVisible && (
            <ConfirmDialog
                onClose={handleLogoutCloseModal}
                onConfirm={handleLogoutConfirm}
            />
        )}

      </div>

      {isFeedbackVisible && (
          <InputFeedbackDialog onClose={handleFeedbackCloseModal} />
      )}

    </PullToRefresh>
  );
};
