import React from 'react';

export const TextMarquee = ({ text }) => {

    return (
        <div className="h-[50px] flex align-items">
            {text}
        </div>
    );
};
