import { useState, useEffect, useCallback } from "react";

function useTimer({
  expireTime,
  autostart = false,
  countdown = false,
  onExpire = () => {},
}) {
  const [seconds, setSeconds] = useState(countdown ? expireTime : 0);
  const [isRunning, setIsRunning] = useState(false);
  const [intervalId, setIntervalId] = useState(null);
  const [expired, setExpired] = useState(false);

  const start = useCallback(() => {
    setSeconds(countdown ? expireTime : 0);
    if (!isRunning && !intervalId) {
      setIsRunning(true);
      const newIntervalId = setInterval(() => {
        setSeconds((prevSeconds) => {
          // Use a functional update to access the latest value
          if (countdown) {
            return Math.max(0, prevSeconds - 1); // Countdown from expireTime
          } else {
            return (prevSeconds + 1) % expireTime; // Update and handle rollover
          }
        });
      }, 1000); // Update every second
      setIntervalId(newIntervalId);
    }
  }, [isRunning, countdown, expireTime, intervalId]);

  const pause = useCallback(() => {
    if (isRunning) {
      setIsRunning(false);
      clearInterval(intervalId);
    }
  }, [isRunning, intervalId]);

  const resume = useCallback(() => {
    if (!isRunning) {
      start();
    }
  }, [start, isRunning]);

  const stop = useCallback(() => {
    pause();
    setSeconds(0); // Reset to initial state for countdown or 0 for counting up
    setIsRunning(false); // Update isRunning to indicate stopped state
    setIntervalId(null);
  }, [pause]);

  const reset = useCallback(() => {
    stop();
  }, [stop]);

  useEffect(() => {
    if (autostart && !isRunning && !expired) {
      if (start.runtimes !== 1) {
        start(); // Start the timer if autostart is true
        start.runtimes = 1;
      }
    }

    return () => clearInterval(intervalId); // Cleanup on unmount
  }, [intervalId, autostart, start, isRunning, expired]);

  // Check for stopping condition in useEffect
  useEffect(() => {
    if (isRunning) {
      if (
        (countdown && seconds === 0) ||
        (!countdown && seconds === expireTime - 1)
      ) {
        setExpired(true);
        onExpire();
        stop();
      }
    }
  }, [isRunning, countdown, expireTime, stop, seconds, onExpire]); // Depend on stop to avoid infinite loop

  return { seconds, start, pause, resume, stop, reset, isRunning };
}

export default useTimer;
