import { useEffect, useRef } from "react";
import ic_whole from "../assets/home/ic_whole_def@3x.png";
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode } from "swiper/modules";

export const Categories = ({
  categoryItems,
  currentItemId,
  itemClassName,
  activeItemClassName,
  moreAction,
  jumpAction = () => {},
}) => {
  const apiRef = useRef(null);
  useEffect(() => {
    let index = categoryItems.findIndex((v) => v.id + "" === currentItemId) - 3;
    index = index < 0 ? 0 : index;
    apiRef.current.swiper.slideTo(index);
  }, [currentItemId, categoryItems]);
  return (
    <div className="items-center relative text-[17px] py-[10px]">
      <Swiper
        ref={apiRef}
        slidesPerView="auto"
        spaceBetween={10}
        freeMode={true}
        modules={[FreeMode]}
        className="myswiper !mr-10 !flex items-center"
        wrapperClass="items-center"
      >
        {categoryItems?.map(({ name, id }) => (
          <SwiperSlide
            key={id}
            className={`!w-auto transition-all ${id + "" === currentItemId ? activeItemClassName : itemClassName}`}
            onClick={() => {
              jumpAction(id);
            }}
          >
            {name}
          </SwiperSlide>
        ))}
      </Swiper>
      {moreAction && (
        <img
          src={ic_whole}
          alt=""
          className="w-11 shadow-[-6px_0_10px_#161823] absolute right-[-8px] -translate-y-1/2 top-1/2"
          onClick={moreAction}
        />
      )}
    </div>
  );
};
