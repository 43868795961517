import { useCallback, useState } from "react";
export const Image = ({
  src,
  defaultPic,
  onError,
  onLoad,
  wrapperClassName,
  imgClassName,
  onClick,
}) => {
  const [isShowDefaultPic, setShowDefaultPic] = useState(true);
  const [isShowImg, setShowImg] = useState(true);
  const errorHandler = useCallback(
    (e) => {
      setShowDefaultPic(true);
      setShowImg(false);
      onError && onError(e);
    },
    [onError],
  );
  const loadHandler = useCallback(
    (e) => {
      setShowDefaultPic(false);
      onLoad && onLoad(e);
    },
    [onLoad],
  );
  return (
    <div className={`relative ${wrapperClassName || imgClassName}`}>
      {isShowImg && (
        <img
          src={src}
          alt=""
          className={imgClassName}
          onError={errorHandler}
          onLoad={loadHandler}
          onClick={onClick}
        />
      )}
      {isShowDefaultPic && (
        <img
          src={defaultPic}
          alt=""
          className={`absolute top-0 left-0 ${imgClassName}`}
        />
      )}
    </div>
  );
};
