import ic_mute_white from "../assets/video/ic_mute_white@3x.png";
import ic_add from "../assets/home/ic_add@3x.png";
import ic_del from "../assets/home/ic_del@3x.png";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { SortableItem } from "../components/SortableItem";
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
} from "@dnd-kit/sortable";
import { useTranslation } from "react-i18next";
import { setCustomizedCategories } from "../store/actions/homeActions";
import { useNavigate } from "react-router-dom";
export const CategoriesPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isEdit, setEdit] = useState(false);
  const { myCates, restCates } = useSelector(
    (state) => state.home.customizedCategories,
  );
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 5,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );
  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (active?.id !== over?.id) {
      const oldIndex = myCates.findIndex((v) => v.id === active.id);
      const newIndex = myCates.findIndex((v) => v.id === over.id);
      dispatch(
        setCustomizedCategories({
          myCates: arrayMove(myCates, oldIndex, newIndex),
          restCates: restCates,
        }),
      );
    }
  };

  const addToMyCates = (id) => {
    dispatch(
      setCustomizedCategories({
        myCates: [...(myCates || []), restCates.find((v) => v.id === id)],
        restCates: [...restCates.filter((v) => v.id !== id)],
      }),
    );
  };

  const removeFromMyCates = (id) => {
    dispatch(
      setCustomizedCategories({
        restCates: [...(restCates || []), myCates.find((v) => v.id === id)],
        myCates: [...myCates.filter((v) => v.id !== id)],
      }),
    );
  };
  return (
    <>
      <div className="px-3">
        <div className="flex justify-between items-center border-b border-b-carrot-deactive">
          <div className="w-1/5">
            <img
              className="w-11 h-11 p-3 -ml-4 "
              src={ic_mute_white}
              alt=""
              onClick={() => {
                navigate("/index");
              }}
            />
          </div>
          <div className="text-white w-3/5 text-[17px] text-center font-medium">
            {t("channel")}
          </div>
          <div
            className="text-carrot-slides-indicator-sel w-1/5 text-[17px] text-right"
            onClick={() => {
              setEdit(!isEdit);
            }}
          >
            {isEdit ? t("channel_complete") : t("channel_edit")}
          </div>
        </div>
        <div className="px-1">
          <div className="flex text-white items-end mt-4">
            <div className="text-[17px] font-medium">
              {t("channel_my_channel")}
            </div>
            {isEdit && (
              <div className="text-sm ml-3">{t("channel_presstosort")}</div>
            )}
          </div>
          <div className="flex justify-start flex-wrap">
            {isEdit ? (
              <DndContext
                sensors={sensors}
                collisionDetection={closestCenter}
                onDragEnd={handleDragEnd}
              >
                <SortableContext items={myCates || []}>
                  {myCates?.map((item) => (
                    <SortableItem
                      key={item.id}
                      id={item.id}
                      className="w-[76px] h-8 text-[#808182] bg-[#42444D] rounded-full flex justify-center items-center mr-2.5 mt-3 relative"
                    >
                      {item.name}
                      {isEdit && (
                        <img
                          src={ic_del}
                          alt=""
                          className="absolute w-3.5 h-3.5 -top-1 -right-1"
                          onClick={() => {
                            removeFromMyCates(item.id);
                          }}
                        />
                      )}
                    </SortableItem>
                  ))}
                </SortableContext>
              </DndContext>
            ) : (
              myCates?.map((item) => (
                <div
                  key={item.id}
                  id={item.id}
                  className="w-[76px] h-8 text-[#808182] bg-[#42444D] rounded-full flex justify-center items-center mr-2.5 mt-3 relative"
                >
                  {item.name}
                  {isEdit && (
                    <img
                      src={ic_del}
                      alt=""
                      className="absolute w-3.5 h-3.5 -top-1 -right-1"
                      onClick={() => {
                        removeFromMyCates(item.id);
                      }}
                    />
                  )}
                </div>
              ))
            )}
          </div>

          <div className="flex text-white items-end mt-4">
            <div className="text-[17px] font-medium">
              {t("channel_all_channel")}
            </div>
            {isEdit && (
              <div className="text-sm ml-3">{t("channel_clicktoadd")}</div>
            )}
          </div>
          <div className="flex justify-start flex-wrap">
            {restCates &&
              Object.values(restCates).map((v) => (
                <div
                  key={v.id}
                  className="w-[76px] h-8 text-[#808182] bg-[#42444D] rounded-full flex justify-center items-center mr-2.5 mt-3 relative"
                >
                  {isEdit && (
                    <img
                      src={ic_add}
                      alt=""
                      className="absolute w-3.5 h-3.5 -top-1 -right-1"
                      onClick={() => {
                        addToMyCates(v.id);
                      }}
                    />
                  )}
                  {v.name}
                </div>
              ))}
          </div>
        </div>
      </div>
    </>
  );
};
