import { combineReducers } from "redux";
import userReducer from "./userSlice";
import homeReducer from "./homeData";
import articleReducer from "./aritcleSlice";
import searchHistoryReducer from "./searchHistory";
import advsReducer from "./advs";

const rootReducer = combineReducers({
  user: userReducer,
  home: homeReducer,
  article: articleReducer,
  searchHistory: searchHistoryReducer,
  advs: advsReducer,
});

export default rootReducer;
