import { createAction, createSelector } from "@reduxjs/toolkit";

export const setAdvs = createAction("advs/setAdvs", (payload) => ({
  payload,
}));

export const setStartUpShowed = createAction(
  "advs/setStartUpShowed",
  (payload) => ({
    payload,
  }),
);

export const setNotifications = createAction(
  "advs/setNotifications",
  (payload) => ({
    payload,
  }),
);

export const setNoticeShowed = createAction(
  "advs/setNoticeShowed",
  (payload) => ({
    payload,
  }),
);

export const getAdvByPosition = createSelector(
  [
    (state) => {
      return state.advs.advList;
    },
    (state, position) => {
      return position;
    },
  ],
  (advList, position) => {
    return advList?.filter((v) => v.position === position);
  },
);
