import router from "../routes";
export const jumpUrl = (jump_url) => {
  if (jump_url) {
    if (jump_url.startsWith("http")) {
      window.open(jump_url); // 通过浏览器打开URL
    } else {
      router.navigate(jump_url);
    }
  }
};

export const disableScroll = () => {
  document.body.style.setProperty("overflow", "hidden");
  document.body.style.setProperty("touch-action", "none");
};

export const enableScroll = () => {
  document.body.style.setProperty("overflow", "");
  document.body.style.setProperty("touch-action", "");
};
